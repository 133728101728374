import Rows from './Rows';

const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"
const Text = "text-sm text-left leading-6 whitespace-nowrap px-5 py-3"

function Table({data,admin,emission}:any) {
    
  return (
    <div className='overflow-x-hidden relative w-full h-[400px] overflow-y-auto'>
        <table className='w-full table-auto border border-border divide-y divide-border'>
            <thead>
                <tr className='bg-dryGray'>
                    <th scope="col" className={`${Head}`}>
                        Video
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Descritpion
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Date de publication
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className='bg-main divide-y divide-gray-800'>
                {data.map((movie:any,i:number)=>(
                    <Rows movie={movie} key={i} admin={admin} emission = {emission}/>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default Table