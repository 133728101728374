import React from 'react'
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'

function Rating({value}:any) {
  return (
    <>
      <div className='flex'>
        <span className='flex'>
            {
                value >= 1 ?(
                  <FaStar className='text-yellow-500'/>
                ) : value >= 0.5 ? (<FaStarHalfAlt className='text-yellow-500'/>) : (<FaRegStar/>)
            }
        </span>
        <span className='flex'>
            {
                value >= 2 ?(
                  <FaStar className='text-yellow-500'/>
                ) : value >= 1.5 ? (<FaStarHalfAlt className='text-yellow-500'/>) : (<FaRegStar/>)
            }
        </span>
        <span className='flex'>
            {
                value >= 3 ?(
                  <FaStar className='text-yellow-500'/>
                ) : value >= 2.5 ? (<FaStarHalfAlt className='text-yellow-500'/>) : (<FaRegStar/>)
            }
        </span>
        <span className='flex'>
            {
                value >= 4 ?(
                  <FaStar className='text-yellow-500'/>
                ) : value >= 3.5 ? (<FaStarHalfAlt className='text-yellow-500'/>) : (<FaRegStar/>)
            }
        </span>
        <span className='flex'>
            {
                value >= 5 ?(
                  <FaStar className='text-yellow-500'/>
                ) : value >= 4.5 ? (<FaStarHalfAlt className='text-yellow-500'/>) : (<FaRegStar/>)
            }
        </span>
      </div>
    </>
  )
}

export default Rating