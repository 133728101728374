import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useParams } from 'react-router-dom'
import { Movies } from '../../../Data/VideoData'
import MovieInfo from '../Components/VideoInfo'
import MovieCasts from '../Components/VideoCasts'
import MovieRates from '../Components/VideoRates'
import Titles from '../Components/Titles'
import { BsCollectionFill } from 'react-icons/bs'
import Movie from '../Components/Video'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../Store/Store'
import { useSelector } from 'react-redux'
import { GetAllposts } from '../../../Toolkit/PostAction'

function SingleMovie() {
  const dispatch = useDispatch<AppDispatch>()
  const posts = useSelector((state:any)=>state.post.lists)
  const message = useSelector((state:any)=>state.post.message)
  const  {id} = useParams()
  const movie= posts.find((post:any)=>post.id == id)
  /*
  const RelatedMovies = Movies.filter((movie)=>movie.category === movie.category)
  */
  useEffect(()=>{
    dispatch(GetAllposts())
  },[message])
  return (
    <Layout>
      {movie &&(
        <MovieInfo movie={movie}/>
      )}
      <div className='container mx-auto min-h-screen px-2 my-6 overflow-hidden'>
        <MovieCasts/>
        <MovieRates movie={movie} />
        {/*
        <div className='my-16'>
          <Titles title="Vidéos dans la même emission" Icon={BsCollectionFill}/>
          <div className='grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-2 gap-6'>
          {RelatedMovies.map((movie,index)=>(
            <Movie key={index} movie={movie}/>
          ))}
        </div>
        </div>
         */}
      </div>
    </Layout>
  )
}

export default SingleMovie