import { configureStore } from '@reduxjs/toolkit';
import authAction from '../Toolkit/authAction';
import AnimatorAction from '../Toolkit/AnimatorAction';
import ArtistAction from '../Toolkit/ArtistAction';
import EmissionAction from '../Toolkit/EmissionAction';
import MusicAction from '../Toolkit/MusicAction';
import QuizAction from '../Toolkit/QuizAction';
import ShortAction from '../Toolkit/ShortAction';
import SponsorsActions from '../Toolkit/SponsorsActions';
import FavouriteAction from '../Toolkit/FavouriteAction';
import EventAction from '../Toolkit/EventAction';
import PostAction from '../Toolkit/PostAction';
import TeaserAction from '../Toolkit/TeaserAction';

export const store = configureStore({
  reducer: {
    auth:authAction,
    animator : AnimatorAction,
    artist:ArtistAction,
    emission:EmissionAction,
    music:MusicAction,
    quiz:QuizAction,
    short:ShortAction,
    sponsor:SponsorsActions,
    favourite:FavouriteAction,
    event : EventAction,
    post:PostAction,
    teaser:TeaserAction
  },
})

export type AppDispatch = typeof store.dispatch;

