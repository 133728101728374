import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { backendURL } from '../Config/baseUrl'


interface animator {
    name: string,
    about: string,
    profilePicture?:string,
    contact: string,
    facebook_username: string,
    tvShows: Array<string>
  
}
interface AnimatorWithId{
  animat:animator,
  id:number
}
interface animatorRate {
    id : number,
    stars :number
}

interface animatorState {
    loading: boolean,
    lists: Array<animator>,
    error: string|null,
    message:string|null,
}
  export const GetAllAnimators = createAsyncThunk(
    'animators/all',
    async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/animators`,
          config
        )
        return data.animators
      } catch (error:any) {
        return error.message
      }
    }
  )
  export const AnimatorCreate = createAsyncThunk(
    'animators/add',
    async (animatorInfo:any,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': "multipart/form-data",
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.post(
            `${backendURL}/animator`,
            animatorInfo,
            config
          )
          return data.message
        } else {
          throw new Error("Le jéton est périmé");
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const RateAnimator = createAsyncThunk(
    'animators/rate',
    async (animatorRate:animatorRate,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.patch(
            `${backendURL}/animator/stars/${animatorRate.id}?stars={${animatorRate.stars}`,
            config
          )
          return data.message
        } else {
          throw new Error("Le jéton est périmé,veuillez reconnecter");
          
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const RateAverage = createAsyncThunk(
    'animators/rateaverage',
    async (id:number,{ rejectWithValue }) => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/animator/stars/average/${id}`,
          config
        )
        return data
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const AnimatorUpdate = createAsyncThunk(
    'animators/update',
    async (animatorInfo:AnimatorWithId,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
        const  {data} = await axios.post(
          `${backendURL}/animator/update/${animatorInfo.id}`,
          animatorInfo.animat,
          config
        )
        return data.message
        } else {
          throw new Error("Le jéton est périmé,veuillez-reconnecter");
          
        }
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const AnimatorDelete = createAsyncThunk(
    'animators/delete',
    async (id:number,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.delete(
            `${backendURL}/animator/delete/${id}`,
            config
          )
          return data.message
        } else {
          throw new Error("Le jéton est périmé,veuillez reconnecter");
          
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )

  const initialState:animatorState = {
    loading: false,
    lists:[],
    error:null,
    message:null,
  }
  
  const animatorSlice = createSlice({
    name: 'animator',
    initialState,
    reducers: {
      seterrornull: (state) => {
        state.error = null
      },
      setMessagenull: (state) => {
        state.message = null
      },
      
    },
    extraReducers: (builder)=>{
        builder.addCase(GetAllAnimators.pending, (state:animatorState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(GetAllAnimators.fulfilled, (state:animatorState,{ payload } :any) => {
            state.loading = false
            state.lists = payload
        })
        builder.addCase(GetAllAnimators.rejected, (state:animatorState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
        builder.addCase(AnimatorCreate.pending, (state:animatorState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(AnimatorCreate.fulfilled, (state:animatorState,{ payload } :any) => {
            state.loading = false
            state.message = "Ajout de votre animateur avec succèss"  
        })
        builder.addCase(AnimatorCreate.rejected, (state:animatorState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
        builder.addCase(RateAnimator.pending, (state:animatorState, { payload }:any) => {
            state.loading = true
            state.error = null
          })
        builder.addCase(RateAnimator.fulfilled, (state:animatorState,{ payload } :any) => {
              state.loading = false
              state.message = payload  
          })
        builder.addCase(RateAnimator.rejected, (state:animatorState, { payload }:any) => {
              state.loading = false
              state.error = payload
        })
        builder.addCase(RateAverage.pending, (state:animatorState, { payload }:any) => {
            state.loading = true
            state.error = null
          })
        builder.addCase(RateAverage.fulfilled, (state:animatorState,{ payload } :any) => {
              state.loading = false
              state.message = payload  
          })
        builder.addCase(RateAverage.rejected, (state:animatorState, { payload }:any) => {
              state.loading = false
              state.error = payload
        });
        builder.addCase(AnimatorUpdate.pending, (state:animatorState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(AnimatorUpdate.fulfilled, (state:animatorState,{ payload } :any) => {
            state.loading = false
            state.message = "mise à jour  de votre animteur avec succèss"  
        })
        builder.addCase(AnimatorUpdate.rejected, (state:animatorState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
        builder.addCase(AnimatorDelete.pending, (state:animatorState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(AnimatorDelete.fulfilled, (state:animatorState,{ payload } :any) => {
            state.loading = false
            state.message = "Suppression de votre animteur avec succèss"  
        })
        builder.addCase(AnimatorDelete.rejected, (state:animatorState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
    }
  })    

export default animatorSlice.reducer
export const {seterrornull,setMessagenull} = animatorSlice.actions