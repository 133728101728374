import RowsSponsor from "./RowsSponsor"

const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"
const Text = "text-sm text-left leading-6 whitespace-nowrap px-5 py-3"

function TableSponsor({data,admin}:any) {
    
  return (
    <div className='overflow-x-hidden relative w-full h-[400px] overflow-y-auto'>
        <table className='w-full table-auto border border-border divide-y divide-border'>
            <thead>
                <tr className='bg-dryGray'>
                    <th scope="col" className={`${Head}`}>
                        Logo
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Nom
                    </th>
                    <th scope="col" className={`${Head}`}>
                        À propos
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Site
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Actions
                    </th>
                </tr>
            </thead>
            {data&&(
                <tbody className='bg-main divide-y divide-gray-800'>
                    {data.map((sponsor:any,i:number)=>(
                        <RowsSponsor sponsor={sponsor} key={i} admin={admin}/>
                    ))}
                </tbody>
            )}
        </table>
    </div>
  )
}

export default TableSponsor