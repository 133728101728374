import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Sidebar from '../Components/Sidebar'
import { HiPlusCircle } from "react-icons/hi";
import TableTeaser from '../Components/TableTeaser';
import { GetAllteasers, setMessageNull, seterrornull } from '../../../Toolkit/TeaserAction';
import { AppDispatch } from '../../../Store/Store';
import CreateTeaser from '../Components/Modals/Create/CreateTeaser';
import { IoIosCloseCircleOutline } from "react-icons/io";

function Teasers() {
  const dispatch = useDispatch<AppDispatch>()
  const teasers = useSelector((state:any)=>state.teaser.lists)
  const message = useSelector((state:any)=>state.teaser.message)
  const error = useSelector((state:any)=>state.teaser.error)
  const [openModalCreate,setModalOpenCreate]=useState(false)
  useEffect(()=>{
    dispatch(GetAllteasers())
  },[message,dispatch])
  return (
    <>
      <Sidebar>
          <CreateTeaser modalOpen={openModalCreate} setModalOpen={setModalOpenCreate}/>
          <div className='flex flex-col gap-6'>
              <div className='flex-btn gap-2'>
                  <h2 className='text-xl font-bold'>Teasers</h2>
                  <button onClick={()=>setModalOpenCreate(true)} className='bg-subMain flex-rows gap-4 font-medium transitions hover:bg-main border border-subMain text-white py-2 px-4 rounded'>
                  <HiPlusCircle/> Create
                  </button>
              </div>
              <TableTeaser data={teasers} admin={true}/>
          </div>
      </Sidebar>
      {message&&(
        <div
        className="absolute left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
        role="alert">
        <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">
            {message}
            </p>
        </div>
        <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>dispatch(setMessageNull())}/>
        </div>
        </div>
    )}
    {error&&(
        <div
        className="absolute left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
        role="alert">
        <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">
            {error}
            </p>
        </div>
        <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>dispatch(seterrornull())}/>
        </div>
        </div>
    )}
    </>
  )
}

export default Teasers