import {useState} from 'react'
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa'
import { MdDelete, MdQuiz } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { GoEye } from "react-icons/go";
import DeleteQuiz from './Modals/Delete/DeleteQuiz';
import UpdateQuiz from './Modals/Update/UpdateQuiz';
import QuizQuestion from './Modals/QuizQuestion';

export default function RowsQuiz ({quiz,i,admin}:any){
    const [openModal,setOpenModal]=useState(false)
    const [openModalDelete,setOpenModalDelete]=useState(false)
    const [openList,setOpenList] = useState(false)
    const Text = "text-sm text-center leading-6 whitespace-nowrap px-5 py-3"
    return(
        <>     
            <QuizQuestion modalOpen={openList} setModalOpen={setOpenList} quiz={quiz}/>
            <DeleteQuiz modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} quiz={quiz}/>
            <UpdateQuiz modalOpen={openModal} setModalOpen={setOpenModal} quiz={quiz}/>
            <tr key={i}>
                <td className={`${Text} flex text-left items-center gap-1`}>
                    <span>
                        <MdQuiz className='text-subMain'/>
                    </span>{quiz.title}
                </td>
                <td className={`${Text}`}>{quiz.created_at}</td>
                <td className={`${Text}`}>{(JSON.parse(quiz.playedBy)).length}</td>
                <td className={`${Text} float-left flex-rows gap-2`}>
                    {admin ? (
                        <>
                            <button className='bg-dry text-white rounded flex-colo w-6 h-6'>
                                    <GoEye onClick={()=>setOpenList(true)}/>
                                </button>
                            <button onClick={()=>setOpenModal(true)} className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                <FaEdit className='text-green-500'/>
                            </button>
                            <button onClick={()=>setOpenModalDelete(true)} className='bg-subMain text-white rounded flex-colo w-7 h-7'>
                                <MdDelete/>
                            </button>

                        </>
                    ):(

                        <>
                            <button className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Download <FaCloudDownloadAlt className='text-green-500'/>
                            </button>
                            <Link to={`#`}>
                                <button className='bg-subMain text-white rounded flex-colo w-6 h-6'>
                                    <GoEye/>
                                </button>
                            </Link>
                        </>
                    )
                    }
                </td>
            </tr>
        </>
    )
}