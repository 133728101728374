import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import { HiPlusCircle } from "react-icons/hi";
import CreateShort from '../Components/Modals/Create/CreateShort';
import TableShort from '../Components/TableShort';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllshort, setMessageNull, seterrornull } from '../../../Toolkit/ShortAction';
import { AppDispatch } from '../../../Store/Store';
import { IoIosCloseCircleOutline } from "react-icons/io";

function ShortList() {
    const [modalOpen,setModalOpen]=useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const shorts = useSelector((state:any)=>state.short.lists)
    const message = useSelector((state:any)=>state.short.message)
    const error = useSelector((state:any)=>state.short.error)
    useEffect(()=>{
        dispatch(GetAllshort())
      },[message,dispatch])
  return (
    <>
        <Sidebar>
            <CreateShort modalOpen={modalOpen} setModalOpen={setModalOpen}/>
            <div className='flex flex-col gap-6'>
                <div className='flex-btn gap-2'>
                    <h2 className='text-xl font-bold'>Liste de short</h2>
                    <button 
                    onClick={()=>setModalOpen(true)}
                    className='bg-subMain flex-rows gap-4 font-medium transitions hover:bg-main border border-subMain text-white py-2 px-4 rounded'>
                    <HiPlusCircle/> Ajouter un short
                    </button>
                </div>
                <TableShort data={shorts} admin={true}/>
            </div>
        </Sidebar>
        {message&&(
        <div
        className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
        role="alert">
        <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">
            {message}
            </p>
        </div>
        <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>dispatch(setMessageNull())}/>
        </div>
        </div>
    )}
    {error&&(
        <div
        className="fixed left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
        role="alert">
        <div className="text-[12px] flex gap-8 items-center">
            <p className="text-gray-100">
            {error}
            </p>
        </div>
        <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>dispatch(seterrornull())}/>
        </div>
        </div>
    )}
        
    </>
  )
}

export default ShortList