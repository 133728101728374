import React,{useState,ChangeEvent} from 'react'
import MainModal from '../../Modals/MainModal'
import {  MdUpload } from 'react-icons/md'
import { BsImage } from 'react-icons/bs'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CirclesWithBar } from  'react-loader-spinner'
import * as yup from "yup";
import convertirDate, { convertirChaineEnDateLocale } from '../../../../../Config/FormatDate'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../../Store/Store'
import { UpdateEvent  as Update} from '../../../../../Toolkit/EventAction';

const options = [
    {
        title :"OUI",
        value : true
    },
    {
        title :"Non",
        value : false
    }
]
const schema = yup.object({
    legend:yup.string().required(),
    date: yup.date().required("La date est important"),
    place: yup.string().required(),
    ticketPlace: yup.string().required(),
    important: yup.boolean().required(),
  }).required();
  type FormData = yup.InferType<typeof schema>;

function UpdateEvent({modalOpen,setModalOpen,ev}:any) {
    const error = useSelector((state:any)=>state.event.error)
    const loading = useSelector((state:any)=>state.event.loading)
    const message = useSelector((state:any)=>state.event.message)
    const dispatch = useDispatch<AppDispatch>()
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<FormData>({
        resolver: yupResolver(schema),
      });
    const onSubmit = async(data:FormData)=>{
        try {
            const event={legend: data.legend,
                date: convertirDate(data.date),
                place: data.place,
                ticketPlace: data.ticketPlace,
                important: data.important}
            const id = ev.id
            dispatch(Update({event,id})).then((res: any) => {
                setModalOpen(false);
              });
        } catch (error) {
            return error
        }
    }
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className='inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl'>
      {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="100"
                    width="100"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
        <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-6'>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Description</label>
                        <input 
                        defaultValue={ev.post.legend}
                        {...register("legend")}
                        name="legend"
                        type="text"
                        placeholder="Description de votre événement..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.legend?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">L'endroit exacte de l'évenement</label>
                        <input 
                        defaultValue={ev.place}
                        {...register("place")}
                        name="place"
                        type="text"
                        placeholder="Lieu..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.place?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Date de l'évenement</label>
                        <input 
                        defaultValue={convertirChaineEnDateLocale(ev.date)}
                        {...register("date")}
                        name="date"
                        type="date"
                        placeholder="Date..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.date?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Ticket place de l'évenement</label>
                        <input 
                        defaultValue={ev.ticketPlace}
                        {...register("ticketPlace")}
                        name="ticketPlace"
                        type="text"
                        placeholder="SuperMusic Analakely..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.ticketPlace?.message}</p>
                    </div>
                </div>
                <div className='w-full grid md:grid-cols-2 gap-6 grid-cols-2'>
                    <div className='text-sm w-full col-span-2'>
                        <label className="text-subMain font-semibold">Important ?</label>
                        <select 
                        defaultValue={ev.important}
                        {...register('important')} name="important" className="w-full h-16 mt-2 px-6 py-4 text-text bg-main border border-border rounded">
                            {options.map((option:any,i:number)=>(
                                <option key={i} value={option.value}> 
                                    {option.title}
                                </option>
                            ))}
                        </select>
                        <p className="text-red-600 text-left">{errors.important?.message}</p>
                    </div>
                    
                </div>
                <div className='flex justify-end items-center my-4'> 
                    <button type="submit" className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                    <MdUpload/>  Mettre à jour l'annonce
                    </button>
                </div>
            </div>  
        </form>
       </div>
    </MainModal>
  )
}

export default UpdateEvent