import React, { useState } from 'react'
import { Link,NavLink } from 'react-router-dom'
import { CgUser} from 'react-icons/cg'
import { RiVidicon2Fill } from 'react-icons/ri'
import { useMatch } from 'react-router-dom';
import ElementToHide from './Components/FormSearch';

function Navbar() {
    const userToken =localStorage.getItem('adminToken')
    ? localStorage.getItem('adminToken')
    : null
    const hover = "hover:text-subMain transitions text-white"
    const Hover = ({isActive}:any)=>(isActive ? 'text-subMain' : hover) 
  return (
    <div className='bg-gradient-to-b from-dry to-main sticky top-0 z-20 overflow-hidden'>
        <div className='container mx-auto py-6 px-2 lg:grid gap-10 grid-cols-7 justify-between items-center'>
            <div className='col-span-1 lg:block hidden'>
                <Link to={"/"}>
                    <img 
                        src="/assets/images/logo.png"
                        alt="logo"
                        className='w-full h-12 object-contain'
                    />
                </Link>
            </div>
            <ElementToHide/>
            <div className='col-span-3 font-medium text-[11px] hidden xl:gap-14 2xl:gap-20 justify-between lg:flex xl:justify-end items-center'>
                <NavLink to="/about-us" className={Hover}>
                    Qui sommes nous?
                </NavLink>
                <NavLink to="/contact-us" className={Hover}>
                    Contactez-nous
                </NavLink>
                <NavLink to={userToken ?"/dashboard":"/dashboard/login"} className={Hover}>
                    <CgUser className="w-8 h-8"/>
                </NavLink>
            </div>
        </div>
    </div>
  )
}

export default Navbar