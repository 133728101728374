import React, { useEffect, useState } from 'react'
import { BsFillGridFill } from 'react-icons/bs'
import { FaHandshake, FaListAlt, FaUser } from 'react-icons/fa'
import { MdHelpCenter, MdWebStories } from "react-icons/md";
import { FiSettings } from 'react-icons/fi';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { NavLink } from 'react-router-dom';
import { MdLogout } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { IoIosEasel } from "react-icons/io";
import { MdGroupWork } from "react-icons/md";
import { MdAccessibilityNew } from "react-icons/md";
import { AiOutlineAccountBook } from "react-icons/ai";
import { MdCameraRoll } from "react-icons/md";

import Layout from '../../Client/Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../Store/Store';
import { GetAllemissions } from '../../../Toolkit/EmissionAction';

function Sidebar({children}:any) {
    const navigate = useNavigate()
    const logout = ()=>{
        localStorage.clear();
        setTimeout(() => {
            navigate("/dashboard/login")
          }, 1000)
      }
    const emissions = useSelector((state:any)=>state.emission.lists)
    const dispatch = useDispatch<AppDispatch>()
    
    useEffect(()=>{
        dispatch(GetAllemissions())
    },[])

    const SideLinks = [
        {
            name:"Evénements",
            link:"/dashboard/event",
            icon:AiOutlineAccountBook
        },
        {
            name:"Shorts",
            link:"/dashboard/short",
            icon:MdCameraRoll
        },
        {
            name:"Teasers",
            link:"/dashboard/teaser",
            icon:MdGroupWork
        },
        {
            name:"Emissions",
            link:"/dashboard/emission",
            icon:IoIosEasel
        },
        {
            name:"Music",
            link:"/dashboard/music",
            icon:IoIosEasel
        },
        {
            name:"Animateurs",
            link:"/dashboard/animators",
            icon:MdAccessibilityNew
        },
        {
            name:"Quiz",
            link:"/dashboard/quiz",
            icon:MdHelpCenter
        },
        {
            name:"Sponsor",
            link:"/dashboard/sponsor",
            icon:FaHandshake
        },
        /*
        {
            name:"Mettre à jour mon profil",
            link:"/dashboard/profile",
            icon:FiSettings
        },
        {
            name:"Changer mon mot de passe",
            link:"/dashboard/password",
            icon:RxCounterClockwiseClock
        },
         */
        {
            name:"Ajouter un admin",
            link:"/dashboard/register",
            icon:RxAvatar
        }
    ]
  const active = "bg-dryGray text-subMain"
  const hover = "hover:text-white hover:bg-main "
  const inActive = "rounded font-medium text-sm transitions flex gap-3 items-center p-4"
  const Hover = ({isActive}:any)=>isActive ? `${active} ${inActive}`:`${inActive} ${hover}`
  return (
    <Layout>
        <div className='relative min-h-screen container mx-auto px-2'>
            <div className='xl:grid grid-cols-8 gap-10 items-start md:py-12 py-6'>
                <div className='col-span-2 sticky bg-dry border border-gray-800 p-6 rounded-md xl:mb-0 mb-5'>
                    <NavLink to="/dashboard"  className={`${inActive} ${active}`}>
                        <BsFillGridFill/> <p>Tableau de bord</p>
                    </NavLink>
                    <div className="ml-4">
                        <div className="grid grid-cols-12 items-center">
                            <span className="col-span-10 flex items-center gap-2 cursor-pointer font-bold py-2">
                                <BsFillGridFill/> <p>Emissions</p> 
                            </span>
                            <div  className="col-span-2 cursor-pointer">
                                
                            </div>
                        </div>
                    </div>
                    <div className='mx-5'>
                    {
                        emissions.map((link:any,index:number)=>(
                            <NavLink to={`/dashboard/emission/${link.id}`} key={index} className={Hover}>
                               <img src={`https://backend.zamandresyprod.mg/${link.logoPath}`} className="h-4 w-4"/> <p className="text-[12px]">{link.name}</p>
                            </NavLink>
                        ))
                    }
                    </div>
                    {
                        SideLinks.map((li,i)=>(
                            <NavLink to={li.link} key={i} className={Hover}>
                               <li.icon/> <p>{li.name}</p>
                            </NavLink> 
                        ))
                    }
                    <div onClick={logout} className="ml-4 hover:bg-subMain p-2 rounded">
                        <div className="grid grid-cols-12 items-center">
                            <span className="col-span-10 flex items-center gap-2 cursor-pointer font-bold py-2">
                                <MdLogout/> <p>Deconnexion</p> 
                            </span>
                            <div  className="col-span-2 cursor-pointer">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="10"
                data-aos-offset="100"
                className='col-span-6 rounded-md bg-dry border border-gray-800 p-6'>
                    {children}
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default Sidebar