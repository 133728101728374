import React, { useEffect, useState,Fragment } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { Listbox, Transition } from '@headlessui/react'
import { FaAngleDown } from "react-icons/fa"; 

import { AppDispatch } from '../../../Store/Store'
import { GetAllposts } from '../../../Toolkit/PostAction'
import { CategoriesData } from '../../../Data/CategoriesData'
import Layout from '../Layout/Layout'
import Movie from '../Components/Video'


const YearData = [
  {  
      title:"Filtrer par année"
  },
 
  {
      title:"2000-2010"
  },
  {
      title:"2010-2020"
  },
  {
      title:"2020-2030"
  },
]

function MoviePage() {
  const [Category,SetCategory] = useState({title:"Starvan"})
    const [year,setYear] = useState(YearData[0])
    const posts  = useSelector((state:any)=>state.post.lists)
    const message = useSelector((state:any)=>state.post.message)
    const dispatch = useDispatch<AppDispatch>()
    const maxpage = 4
    const [page,setPage]=useState(maxpage)
    useEffect(()=>{
      dispatch(GetAllposts())
    },[message,dispatch])


    const handleCategoryChange = (newCategory:any) => {
        SetCategory(newCategory);
        if (newCategory.title === "dagolife") {
          return 1;
        } else if (newCategory.title === "fantsybobaka") {
          return 2;
        } else if (newCategory.title === "manavatsava") {
          return 3;
        } else if (newCategory.title === "starvan") {
          return 4;
        } else if (newCategory.title === "starvanlive") {
          return 5;
        }
      };
    
      const handleYearChange = (selectedYear:any) => {
        const newYear:any = YearData.find((item) => item.title === selectedYear.title);
        setYear(newYear);
      };

    const Filter = [
        {
            value:Category,
            onChange:handleCategoryChange,
            items:CategoriesData,
        },
        {
            value:year,
            onChange:handleYearChange,
            items:YearData
        }, 
    ]
    
  return (
    <Layout>
      <div className='min-h-screen container mx-auto px-2 my-6 overflow-hidden'>
          <div className='my-6 bg-dry border text-dryGray border-gray-800 grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-2 rounded p-6'>
            {
                Filter.map((item,index)=>(
                    <Listbox key={index} value={item.value} onChange={item.onChange}>
                        <div className='relative'>
                            <Listbox.Button className="border-gray-800  relative w-full bg-main rounded-lg cursor-default py-4 pl-6 pr-10 text-left text-xs ">
                                <span className='block truncate'>{item.value.title}</span>
                                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                    <FaAngleDown className="h-5 w-5" aria-hidden="true"/>
                                </span>
                            </Listbox.Button>
                            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"> 
                                <Listbox.Options className="absolute z-10 mt-1 w-full border border-gray-800 text-dryGray rounded-md shadow-lg bg-white ring-opacity-5 max-h-60 py-1 text-base ring-1 ring-black overflow-auto focus:outline-none sm:text-sm">
                                    {item.items.map((iterm,index)=>(
                                        <Listbox.Option key={index} className={({active})=>`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active ? "bg-subMain text-white" :"text-main"
                                        }`} value={iterm}>
                                            {({selected})=>(
                                                <>
                                                    <span className={`block truncated ${
                                                        selected ? 'font-semibold':'font-normal'
                                                    }`}>
                                                        {iterm.title}
                                                    </span>
                                                    {selected ? (
                                                        <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                                                            <p>
                                                                e
                                                            </p>
                                                        </span>
                                                    ):null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                ))
            } 
        </div>
        <p className="text-lg font-medium my-6 ">
          Total <span className='font-bold text-subMain'>
            {posts?.length} {' '} items found
          </span>
        </p>
        <div className='grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-2 gap-6'>
          {posts.slice(0,page ).map((movie:any,index:any)=>(
            <Movie key={index} movie={movie}/>
          ))}
        </div>
        {/*loading more 
        <div className='w-full flex-colo md:my-20 my-10'>
            <button onClick={HandleLoadingMore} className='flex-rows gap-3 text-white py-3 px-8 rounded font-semibold border-2 border-subMain'>
              Loading More <CgSpinner className='animate-spin'/>
            </button>
        </div>
        */}
      </div>
    </Layout>
  ) 
}

export default MoviePage