import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { backendURL, notifURL } from '../Config/baseUrl'


interface Short {
    title: string,
    short?: File,
    description: string
}

interface ShortInfo {
  short:Short,
  id:number
}

  
interface shortState {
    loading: boolean,
    lists: Array<Short>,
    error: string|null,
    message:null|string
}
  export const GetAllshort = createAsyncThunk(
    'short/all',
    async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/shorts`,
          config
        )
        return data.shorts.reverse()
      } catch (error:any) {
        return error.message
      }
    }
  )
  export const CreateShort = createAsyncThunk(
    'short/add',
    async (shortInfo:Short,{ rejectWithValue }) => {
      try {
        if (localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.post(
            `${backendURL}/short`,
            shortInfo,
            config
          )
          try {
            await axios.post(
              `${notifURL}/notif/short` 
            ).then((res)=>{
              return res
            })
          } catch (error) {
            return error
          }
          return data.message
        } else {
          throw new Error("Erreur de jéton,veuillez reconnecter");
          
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const UpdateShort = createAsyncThunk(
    'short/update',
    async (shortInfo:ShortInfo,{ rejectWithValue }) => {
      try {
        if (localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.put(
            `${backendURL}/short/update/${shortInfo.id}`,
            shortInfo.short,
            config
          )
          return data.message
        } else {
          throw new Error("Erreur de jéton,veuillez reconnecter");
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const DeleteShort = createAsyncThunk(
    'short/delete',
    async (id:number,{ rejectWithValue }) => {
      try {
        if (localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.delete(
            `${backendURL}/short/delete/${id}`,
            config
          )
          return data
        } else {
          throw new Error("Erreur de jéton,veuillez reconnecter");
        }
       
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )

  const initialState:shortState = {
    loading: false,
    lists:[],
    error:null,
    message:null
  }
  
  const shortSlice = createSlice({
    name: 'short',
    initialState,
    reducers: {
      seterrornull: (state) => {
        state.error = null
      },
      setMessageNull: (state) => {
        state.message = null
      }
    },
    extraReducers: (builder)=>{
        builder.addCase(GetAllshort.pending, (state:shortState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(GetAllshort.fulfilled, (state:shortState,{ payload } :any) => {
            state.loading = false
            state.lists = payload
              
        })
        builder.addCase(GetAllshort.rejected, (state:shortState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
        builder.addCase(CreateShort.pending, (state:shortState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(CreateShort.fulfilled, (state:shortState,{ payload } :any) => {
            state.loading = false
            state.message = "Création de votre short avec succèss"
        })
        builder.addCase(CreateShort.rejected, (state:shortState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
        builder.addCase(UpdateShort.pending, (state:shortState, { payload }:any) => {
          state.loading = true
          state.error = null
      })
        builder.addCase(UpdateShort.fulfilled, (state:shortState,{ payload } :any) => {
            state.loading = false
            state.message = "Mise à jour de votre short avec succèss"
        })
        builder.addCase(UpdateShort.rejected, (state:shortState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
        builder.addCase(DeleteShort.pending, (state:shortState, { payload }:any) => {
          state.loading = true
          state.error = null
      })
        builder.addCase(DeleteShort.fulfilled, (state:shortState,{ payload } :any) => {
            state.loading = false
            state.message = "Suppression de votre short avec succèss"
        })
        builder.addCase(DeleteShort.rejected, (state:shortState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
    }
  })    

export const {seterrornull,setMessageNull} = shortSlice.actions
export default shortSlice.reducer