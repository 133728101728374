import { Route, Routes } from "react-router-dom";
import HomeScreen from "../Presentation/Client/Pages/Home";
import AboutUs from "../Presentation/Client/Pages/AboutUs";
import NotFound from "../Presentation/Client/Pages/NotFound";
import ContactUs from "../Presentation/Client/Pages/ContactUs";
import Movies from "../Presentation/Client/Pages/Videos";
import SingleMovie from "../Presentation/Client/Pages/SingleVideo";
import  WatchPage  from "../Presentation/Client/Pages/WatchPage";
import Login from "../Presentation/Admin/Pages/Login";
import Register from "../Presentation/Admin/Pages/Register";
import Profile from "../Presentation/Admin/Pages/Profile";
import Password from "../Presentation/Admin/Pages/Password";
import EventList from "../Presentation/Admin/Pages/EventList";
import Dashboard from "../Presentation/Admin/Pages/Dashboard";
import ShortList from "../Presentation/Admin/Pages/ShortList";
import AddMovie from "../Presentation/Admin/Pages/AddVideos";
import Emission from "../Presentation/Admin/Pages/Emissions/Emissions";
import Animators from "../Presentation/Admin/Pages/Animators";
import Aos from "aos";
import AdminPrivateRoutes from "./AdminPrivateRoute";
import Teasers from "../Presentation/Admin/Pages/Teasers";
import Emissions from "../Presentation/Admin/Pages/Emissions";
import Music from "../Presentation/Admin/Pages/Music";
import Quiz from "../Presentation/Admin/Pages/Quiz/Quiz";
import SponsorList from "../Presentation/Admin/Pages/Sponsor";
import PrivacyPolicyPage from "../Presentation/Client/Pages/Politic";

function App() {
  Aos.init();
  return (
    <Routes>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/music" element={<Music/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/sponsor" element={<SponsorList/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/quiz" element={<Quiz/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
          <Route path="/dashboard/register" element={<Register/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/profile" element={<Profile/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/password" element={<Password/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/emission" element={<Emissions/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/event" element={<EventList/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/short" element={<ShortList/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/teaser" element={<Teasers/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/addmovie" element={<AddMovie/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/animators" element={<Animators/>}/>
      </Route>
      <Route element={<AdminPrivateRoutes />}>
        <Route path="/dashboard/emission/:id" element={<Emission/>}/>
      </Route>
      <Route path="/" element={<HomeScreen/>}/>
      <Route path="/about-us" element={<AboutUs/>}/>
      <Route path="/contact-us" element={<ContactUs/>}/>
      <Route path="/movies" element={<Movies/>}/>
      <Route path="/politic" element={<PrivacyPolicyPage/>}/>
      <Route path="/movies/:id" element={<SingleMovie/>}/>
      <Route path="/watch/:id" element={<WatchPage/>}/>
      <Route path="/dashboard/login" element={<Login/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
}

export default App;
