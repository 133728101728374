import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface ChartDataProps {
  datafinal: any[]; // Define the type of 'datafinal' prop
}


export default class ChartData extends PureComponent<ChartDataProps> {
  static demoUrl = 'https://codesandbox.io/s/customized-legend-event-l19fo';

  state = {
    opacity: {
      uv: 1,
      total_video: 1,
    },
    
  };

  handleMouseEnter = (o:any) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.5 },
    });
  };

  handleMouseLeave = (o:any) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  render() {
    const { opacity } = this.state;
    const {datafinal}:any = this.props
    const data = [
      {
        name: 'Dagolife',
        total_video: datafinal.dagolife,
      },
      {
        name: 'Fantsy bobaka',
        total_video: datafinal.fantsybobaka,
     
      },
      {
        name: 'Manavatsava',
        total_video: datafinal.manavatsava,
     
      },
      {
        name: 'Starvan',
        total_video: datafinal.starvan,
    
      },
     
    ];
  
    return (
      <div style={{ width: '100%',marginTop:"20px" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
            <Line type="monotone" dataKey="total_video" strokeOpacity={opacity.total_video} stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
