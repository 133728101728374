import React from 'react'
import Titles from '../Components/Titles'
import { Emission } from '../../../Data/EmissionData'
import { FaUserFriends } from 'react-icons/fa'
import  {Swiper, SwiperSlide } from 'swiper/react'
import { BsCameraVideo } from 'react-icons/bs'
import { Autoplay } from 'swiper';


function MovieCasts() {
  return (
    <div className='my-12'>
        <Titles title="Emissions" Icon={BsCameraVideo}/>
        <div className='mt-10'>
            <Swiper
                autoplay={{
                    delay:1000,
                    disableOnInteraction:false,
                }} 
                loop={true} 
                speed={100}
                modules={[Autoplay]}
                spaceBetween={10}
                breakpoints={{
                    0:{
                        slidesPerView:1
                    },
                    400:{
                        slidesPerView:2
                    },
                    768:{
                        slidesPerView:3
                    },
                    1024:{
                        slidesPerView:4
                    }
                }}
            >
                {
                    Emission.map((emission,i)=>(
                        <SwiperSlide key={i}>
                            <div className='w-full p-3 italic text-xs text-text rounded flex-colo bg-dry border border-gray-800'>
                                <img src={`/assets/images/${emission.image}`} alt={emission.name} className='w-full h-72 object-cover rounded mb-4'/>
                                <p>{emission.name}</p>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
                
        </div>
    </div>
  )
}

export default MovieCasts