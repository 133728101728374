import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../Store/Store'
import { DeleteEv } from '../../../../../Toolkit/EventAction'
import { CirclesWithBar } from 'react-loader-spinner'
import { QuizDelete } from '../../../../../Toolkit/QuizAction'


export default function DeleteQuiz({modalOpen,setModalOpen,quiz}:any) {
  const loading = useSelector((state:any)=>state.quiz.loading)
  const dispatch = useDispatch<AppDispatch>()
  const HandleDelete = async(id:any)=>{
    dispatch(QuizDelete(id)).then((res)=>{
      setModalOpen(false)
    })
  }
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>setModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="50"
                    width="50"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
                )}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-subMain"
                  >
                    Avertissement!!!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Vous voulez vraiment effacer le quiz <span className='font-bold text-subMain'>{quiz.title}</span> ?,cette action est irréversible.Si vous ne voulez pas l'effacer clickez sur annuler.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={()=>setModalOpen(false)}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-subMain px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:bg-red-700 focus-visible:ring-offset-2"
                      onClick={()=>HandleDelete(quiz.id)}
                    >
                      Effacer
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
