import React,{useState,ChangeEvent} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {  MdUpload } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';
import { MdVideoLibrary } from "react-icons/md";
import { CirclesWithBar } from 'react-loader-spinner';
import { MusicCategories } from '../../../../../Data/CategoriesData';
import { RankingCategorie } from '../../../../../Data/CategoriesData'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import MainModal from '../MainModal'
import createArrayFromInput from '../../../../../Config/AlgoInput';
import { Createmusic as Createmus } from '../../../../../Toolkit/MusicAction';

const schema = yup.object({
    legend: yup.string().required(),
    music1: yup.mixed().label('Votre music'),
    title: yup.string().required(),
    director: yup.string().required(),
    producer: yup.string().required(),
    cover: yup.mixed().label('Votre couverture'),
    ranking: yup.string().required(),
    categories: yup.string().required(),
    artists: yup.string().required(),
  
  }).required();
  type FormData = yup.InferType<typeof schema>;

function CreateMusic({modalOpen,setModalOpen}:any) {
    const loading = useSelector((state:any)=>state.music.loading)
    const dispatch = useDispatch<AppDispatch>()
    const [play,setPlay]=useState(false)
    const [link,setLink]= useState('')
    const [audiolink,setAudioLink]=useState('')
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<FormData>({
        resolver: yupResolver(schema),
      });
    
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setLink(URL.createObjectURL(e.target.files[0]))
        }
      };
    const handleFileChangeAudio = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAudioLink(URL.createObjectURL(e.target.files[0]))
        }
      };
    const HandlePostMusic = async(data:FormData)=>{
        try {
            const music1 = (data.music1 as any[])[0];
            const cover = (data.cover as any[])[0];
            const formData = new FormData();
            formData.append("legend", data.legend);
            formData.append("director", data.director);
            formData.append("producer", data.producer);
            formData.append("cover", cover);
            formData.append("music1",music1);
            formData.append("categories", JSON.stringify(createArrayFromInput(data.categories)));
            formData.append("artists", JSON.stringify(createArrayFromInput(data.artists)));
            formData.append("title", data.title);
            formData.append("ranking", JSON.stringify(createArrayFromInput(data.ranking)));
            
            dispatch(Createmus(formData)).then((res)=>{
                reset()
                setModalOpen(false)
            })
           } catch (error) {
            return error
        }
    }
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className='inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl'>
        <div className='flex flex-col gap-6 relative'>
            {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="100"
                    width="100"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
            <form className='w-full' onSubmit={handleSubmit(HandlePostMusic)}>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-white font-semibold">Legend ou description</label>
                        <input 
                        {...register("legend")}
                        name="legend"
                        type="text"
                        placeholder="Legend ou description"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.legend?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-white font-semibold">Titre de votre music</label>
                        <input 
                        {...register("title")}
                        name="title"
                        type="text" 
                        placeholder="Alefa barea"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.title?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-white font-semibold">Réalisateur du clip</label>
                        <input 
                        {...register("director")}
                        name="director"
                        type="text"
                        placeholder="Réalisateur"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.director?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-white font-semibold">Producteur du clip</label>
                        <input 
                        {...register("producer")}
                        name="producer"
                        type="text" 
                        placeholder="Producteur"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.title?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className='text-sm w-full'>
                        <label className="text-subMain font-semibold">Categories</label>
                        <select {...register('categories')} name="categories" className="w-full h-16 mt-2 px-6 py-4 text-text bg-dry border border-border rounded">
                            {MusicCategories.map((option:any,i:number)=>(
                                <option key={i} value={option}> 
                                    {option}
                                </option>
                            ))}
                        </select>
                        <p className="text-red-600 text-left">{errors.categories?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-white font-semibold">Artistes</label>
                        <input 
                        required 
                        {...register("artists")}
                        name="artists"
                        type="text" 
                        placeholder="Stephanie,Basta lion,...(separéz par un virgule s'il y a plusieurs artistes)"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.artists?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className='flex flex-col gap-2'>
                            <p className='text-white font-semibold text-sm'>
                                Photo de couverture de votre music
                            </p>
                            <div className='w-full'>
                                <label className='flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none'>
                                <span className='flex items-center w-full'>
                                    <BsImage className='text-subMain  h-8 w-8' />
                                    <span className='font-medium text-white text-[12px] w-full'>Ajouter le photo de votre music</span>
                                </span>
                                <input {...register('cover',{
                                        onChange: handleFileChange})} accept="image/*" type='file' name='cover' className='opacity-0 w-full' />
                                </label>
                            </div>
                            <div className='w-16 h-16 p-2 bg-main border border-border rounded'>
                                <img 
                                src={link}
                                alt=""
                                className='w-full h-full object-cover rounded'
                                />
                            </div>
                    </div>
                    <div className='text-sm w-full'>
                        <label className="text-white font-semibold">Ranking</label>
                        <select {...register('ranking')} name="ranking" className="w-full max-w-full h-16 mt-2 px-6 py-4 text-text bg-dry border border-border rounded">
                            {RankingCategorie.map((option:any,i:number)=>(
                                <option key={i} value={option}> 
                                    {option}
                                </option>
                            ))}
                        </select>
                        <p className="text-red-600 text-left">{errors.ranking?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className='flex flex-col gap-2 col-span-2'>
                            <p className='text-white font-semibold text-sm'>
                                Fichier 1(mp4)
                            </p>
                            <div className='w-full'>
                                <label className='flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none'>
                                <span className='flex items-center w-full'>
                                    <MdVideoLibrary className='text-subMain h-8 w-8' />
                                    <span className='font-medium text-white text-[12px] w-full'>Ajouter un mp4 de votre music</span>
                                </span>
                                <input {...register('music1',{
                                    onChange: handleFileChangeAudio})} type='file' name='music1' accept="video/*" className='opacity-0 w-full'/>
                                </label>
                            </div>
                            <div className='w-16 h-16 p-2 bg-main border border-border rounded'>
                                    {audiolink&&(
                                        <video autoPlay={play}  controls className='w-full h-full rounded'>
                                            <source src={audiolink} type="audio/mp3" title="titre"/>
                                        </video>
                                    )}
                            </div>
                    </div>
                </div>
                <div className='flex justify-end items-center my-4'> 
                    <button type="submit" className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                    <MdUpload/>  Ajouter la music
                    </button>
                </div>
            </form>
        </div>  
       </div>
    </MainModal>
  )
}

export default CreateMusic