import React,{useState,ChangeEvent } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {  MdUpload } from 'react-icons/md'
import { BsImage } from 'react-icons/bs';
import { CirclesWithBar } from 'react-loader-spinner';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import MainModal from '../MainModal'
import { SponsorCreate } from '../../../../../Toolkit/SponsorsActions';


const schema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    logo: yup.mixed().required(),
    link:yup.string().required()
   
  }).required();
  type FormData = yup.InferType<typeof schema>;

function CreateSponsor({modalOpen,setModalOpen}:any) {
    const loading = useSelector((state:any)=>state.sponsor.loading)
    const dispatch = useDispatch<AppDispatch>()
    const [link,setLink]= useState('')

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<FormData>({
        resolver: yupResolver(schema),
      });
    
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setLink(URL.createObjectURL(e.target.files[0]))
        }
      };
      
    const HandlePostEmission = (data:FormData)=>{
        try {
            const logo = (data.logo as any[])[0];

            const formData = new FormData();

            if (logo) {
              formData.append('logo', logo);
            }
        
            
            const { name,description,link, ...restData } = data;

            const datafinal = {name,description,link, ...Object.fromEntries(formData.entries()) };

            dispatch(SponsorCreate(datafinal)).then((res: any) => {
              reset()
              setModalOpen(false)
            });
        
           } catch (error) {
            return error
           }
    }
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className='inline-block sm:w-4/5 md:w-4/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl'>
        <div className='flex flex-col gap-6 relative text-left'>
            {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="100"
                    width="100"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
            <form className='w-full' onSubmit={handleSubmit(HandlePostEmission)}>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full col-span-2">
                        <label className="text-gray-400 font-semibold">Le nom de votre partenaire</label>
                        <input 
                        {...register("name")}
                        name="name"
                        type="text"
                        placeholder="Telma"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.name?.message}</p>
                    </div>
                    
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full col-span-2">
                        <label className="text-gray-400 font-semibold">Descripion</label>
                        <input 
                        {...register("description")}
                        name="description"
                        type="text"
                        placeholder="Telecom malagasy dia orinasa iray..."
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.description?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full col-span-2">
                        <label className="text-gray-400 font-semibold">Site de votre partenaire</label>
                        <input 
                        {...register("link")}
                        name="link"
                        type="text"
                        placeholder="https://telma.com"
                        className={`w-full  text-sm h-16  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.link?.message}</p>
                    </div>
                    
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className='flex flex-col gap-2 col-span-2'>
                            <p className='text-gray-400 font-semibold text-sm'>
                                Photo du partenaire(logo)
                            </p>
                            <div className='w-full'>
                                <label className='flex justify-center h-16  transition text-subMain bg-dry border-2 px-6 py-4  border-gray-300 border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-main focus:outline-none'>
                                <span className='flex items-center w-full'>
                                    <BsImage className='text-subMain  h-8 w-8' />
                                    <span className='font-medium text-white text-[12px] w-full'>Ajouter le photo de votre partenaire</span>
                                </span>
                                <input {...register('logo',{
                                        onChange: handleFileChange})} accept='image/*' type='file' name='logo' className='opacity-0' />
                                </label>
                            </div>
                            <div className='w-16 h-16 p-2 bg-main border border-border rounded'>
                                <img 
                                src={link}
                                alt=""
                                className='w-full h-full object-cover rounded'
                                />
                            </div>
                    </div>
                </div>
                <div className='flex justify-end items-center my-4'> 
                    <button type="submit" className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                    <MdUpload/>  Ajouter le partenaire
                    </button>
                </div>
            </form>
        </div>  
       </div>
    </MainModal>
  )
}

export default CreateSponsor