import React,{useState,useEffect} from 'react'
import Sidebar from '../Components/Sidebar'
import Table from '../Components/Table'
import { HiPlusCircle } from 'react-icons/hi'
import CreateAnimator from '../Components/Modals/Create/CreateAnimator'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../Store/Store'
import { GetAllAnimators, setMessagenull } from '../../../Toolkit/AnimatorAction'
import AnimatorTable from '../Components/AnimatorTable'
import { IoIosCloseCircleOutline } from "react-icons/io";

function Animators() {
  const [openModal,setOpenModal]=useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const message = useSelector((state:any)=>state.animator.message)
  const animators = useSelector((state:any)=>state.animator.lists)
  
  useEffect(()=>{
    dispatch(GetAllAnimators())
  },[message])
  return (
    <>
    <Sidebar>
      <CreateAnimator modalOpen={openModal} setModalOpen={setOpenModal}/>
        <div className='flex flex-col gap-6'>
            <div className='flex-btn gap-2'>
                <h2 className='text-xl font-bold'>Liste des animateurs</h2>
                <button onClick={()=>setOpenModal(true)} className='bg-main font-medium transitions hover:bg-subMain border border-subMain text-white py-3 px-6 rounded flex gap-2 items-center'>
                  <HiPlusCircle/>  Ajouter un animateur
                </button>
            </div>
            {animators && animators.length>0 &&(
              <AnimatorTable data={animators} admin={true}/>
            )}
        </div>
    </Sidebar>
    {message&&(
      <div
      className="fixed left-1 bottom-1 rounded-lg bg-green-700 px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
      role="alert">
      <div className="text-[12px] flex gap-8 items-center">
          <p className="text-gray-100">
          {message}
          </p>
      </div>
      <div className="text-white cursor-pointer">
        <IoIosCloseCircleOutline onClick={()=>dispatch(setMessagenull())}/>
      </div>
      </div>
    )}
    </>
  )
}

export default Animators