import React, { useState, ChangeEvent, useEffect } from 'react'
import MainModal from '../Modals/MainModal'
import EditQuestion from '../Input/EditQuestion'
import { HiPlusCircle } from 'react-icons/hi'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CirclesWithBar } from  'react-loader-spinner'
import * as yup from "yup";
import createArrayFromInput, { parseArrayFromString, stringInArrayIgnoreCase } from '../../../../Config/AlgoInput';
import { AddQuestion, setMessageNull } from '../../../../Toolkit/QuizAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../Store/Store';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdDelete } from 'react-icons/md';
import DeleteQuestion from './Delete/DeleteQuestion';
import RowsQuestion from '../RowsQuestion';

const schema = yup.object({
  sentence:yup.string().required(),
  correctAnswer:yup.string().required(),
  possibleAnswer:yup.string().required(),
}).required();
type FormData = yup.InferType<typeof schema>;



function QuizQuestion({ modalOpen, setModalOpen, quiz }: any) {
  const loading = useSelector((state:any)=>state.quiz.loading)
  const dispatch = useDispatch<AppDispatch>()
  const [edit, setEdit] = useState(false)
  const [addquest,setAddquest]=useState(false)
  const message = useSelector((state:any)=>state.quiz.message)
  const  [error,setError] = useState("")
  const [deletequestion,setDeletequestion]=useState(false)
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const PostQuestion  = async(data:FormData)=>{
    try {
      const {sentence,correctAnswer,...rest}=data
      const possibleAnswer = createArrayFromInput(data.possibleAnswer)
      const question = {sentence,correctAnswer,possibleAnswer}
      const id = quiz.id
      const issame:boolean = stringInArrayIgnoreCase(correctAnswer,possibleAnswer)
      if (issame){
        setError("La bonne réponse ne doît pas apparu dans le réponse possible")
      } else {
        possibleAnswer.push(correctAnswer)
        dispatch(AddQuestion({question,id})).then((res)=>{
          setAddquest(false)
          return res
        })
      }
      
    } catch (error) {
      return error
    }
  }
  return (
    <>
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className='inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white rounded-2xl overflow-scroll relative'>
      {loading&&(
          <div className='absolute top-32 lg:left-[450px] md:left-[200px] sm:left-[100px] z-50'>
            <CirclesWithBar
            height="40"
            width="40"
            color="#E90064"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor=""
            innerCircleColor=""
            barColor=""
            ariaLabel='circles-with-bar-loading'
          />
          </div>
            )}
        <div className='flex justify-between items-center gap-2'>
          <div className='w-full'>
            {addquest&&(
            <form className='flex justify-between gap-2' onSubmit={handleSubmit(PostQuestion)}>
              <input 
              required
              {...register("sentence")}
              placeholder='votre question'
              className=' w-full text-sm  h-12 border border-border rounded text-white bg-dry'/>
              <input 
              required
              {...register("correctAnswer")}
              placeholder='Le bonne reponse'
              className=' w-full text-sm  h-12 border border-border rounded text-white bg-dry'/>
              <input 
              required
              {...register("possibleAnswer")}
              placeholder='Autre reponse(separer par un virgule)'
              className=' w-full text-sm  h-12 border border-border rounded text-white bg-dry'/>
              <div className="flex items-center space-x-2 ml-auto">
                  <button type="submit" className="text-main p-2 bg-subMain rounded-md h-12">
                    Ajouter
                  </button>
                  <button onClick={()=>setAddquest(!addquest)} className="text-red-500 p-2">
                    Annuler
              </button>
              </div>
            </form>
            )}
          </div>
          <div>
            {!addquest&& (
            <button onClick={()=>setAddquest(true)} className='bg-main font-medium transitions hover:bg-subMain border border-subMain text-white py-3 px-6 rounded flex gap-2 items-center'>
              <HiPlusCircle />Question
            </button>
            )}
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mt-6'>
          {quiz && quiz.questions.map((question: any, index: number) => (
            <RowsQuestion key={index} question={question}/>
          ))}
        </div>
        {error&&(
          <div
          className="fixed left-1 bottom-1 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
          role="alert">
          <div className="text-[12px] flex gap-8 items-center">
              <p className="text-white">
              {error}
              </p>
          </div>
          <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>setError("")}/>
          </div>
          </div>
        )}
      </div>
    </MainModal>
    
    </>
  )
}

export default QuizQuestion