import React from 'react'
import { BiPlus } from 'react-icons/bi'


import Uploader from '../Uploader'
import MainModal from '../Modals/MainModal'
import { Input } from '../UsedInput'

function CastsModal({modalOpen,setModalOpen,cast}:any) {
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className='inline-block sm:w-4/5 md:w-3/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl'>
            <h2 className='text-2xl font-bold'>
                {cast? "update cast" :"create cast"}
            </h2>
            <form className='flex flex-col gap-6 mt-6 text-left'>
            <Input
                label="cast Name"
                placeholder={cast?cast.title:"John doe"}
                type="text"
                bg={false}
            />
            <Input 
            label="Cast Name"
            placeholder={cast ? cast.fullName : "John Doe"}
            type="text"
            bg={false}
            />
            <div className='flex flex-col gap-2'>
              <p className='text-border font-semibold text-sm'>
                  Image du guest
              </p>
              <Uploader/>
              <div className='w-32 h-32 p-2 bg-main border border-border rounded'>
                  <img 
                  src="/assets/images/user1.jpg"
                  alt="..."
                  className='w-full h-full object-cover rounded'
                  />
              </div>
            </div>
            <button
            onClick={()=>setModalOpen(false)}
            className='w-full flex-rows gap-4 text-lg py-3 font-bold rounded bg-subMain text-white hover:bg-transparent border-2 transitions   border-subMain'
            >
              <BiPlus/>   {cast? "Update" :"Add"}
            </button>
            </form>
        </div>
    </MainModal>
  )
}

export default CastsModal