import React, { useEffect } from 'react'
import Titles from '../Components/Titles'
import { BsBookmarkStarFill, BsFillFileEarmarkPersonFill } from 'react-icons/bs'
import { useState } from 'react'
import { Select } from '../../Admin/Components/UsedInput'
import Rating from '../Components/Stars'
import { Message } from '../../Admin/Components/UsedInput'
import { Animateurs } from '../../../Data/AnimateurData'
import { RatingsAnimateurs } from '../../../Data/AnimateurData'
import { CirclesWithBar } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../Store/Store'
import { GetAllAnimators } from '../../../Toolkit/AnimatorAction'

function truncateTextWithEllipsis(text:string, maxLength:number) {
  if (text.length <= maxLength) {
    // If the text length is already within the limit, return it as it is
    return text;
  } else {
    // Truncate the text and add ellipsis
    return text.slice(0, maxLength) + '...';
  }
}

function MovieRates({movie}:any) {
  const dispatch = useDispatch<AppDispatch>()
  const animateurs = useSelector((state:any)=>state.animator.lists)
  const message = useSelector((state:any)=>state.animator.message)
  useEffect(()=>{
    dispatch(GetAllAnimators())
  },[message])
  const Ratings=[
    {
      title:"0-Poor",
      value:0
    },
    {
      title:"1-Fair",
      value:1
    },
    {
      title:"2-Good",
      value:2
    },
    {
      title:"3-Very Good",
      value:3
    },
    {
      title:"4-Excellent",
      value:4
    },
    {
      title:"5-Masterpiece",
      value:5
    }
  ]
  
  const [rate,setRate]=useState(false);
  const [rating,setRating]=useState();
  const [loading,setLoading]=useState(false)
  const HandleGiveStar = ()=>{
    setLoading(true)
    setTimeout(() => {
     setLoading(false)
     setRate(true)
    }, 2000);
  }
  return (
    <div className='my-12 '>
      <Titles title="Nos animateurs" Icon={BsFillFileEarmarkPersonFill}/>
      <div className='mt-10 xl:grid flex-colo grid-cols-5 gap-12 bg-dry xs:p-10 py-10 px-2 sm:p-20 rounded'>
        <div className='xl:col-span-2 w-full flex flex-col gap-8'>
          <h3 className='text-xl fon-semibold text-white'>
            Noter nos animateurs
          </h3>
          <p className='text-sm leading-7 font-medium  text-white'> 
            Donnez des notes à nos animateurs si vous voulez-bien
          </p>
          <div className='text-sm w-full'> 
            <Select label="Choisir l'animateur" options={RatingsAnimateurs} onChange={(e:any)=>setRating(e.target.value)}/>
            <div className='flex mt-4 text-lg gap-2 text-star'>
                
            </div>
          </div>
          <div className='text-sm w-full'> 
            <Select label='Selectionnez un note' options={Ratings} onChange={(e:any)=>setRating(e.target.value)}/>
            <div className='flex mt-4 text-lg gap-2 text-star'>
                <Rating value={rating}/>
            </div>
          </div>
          <Message label="Message" placeholder="Make it short and sweet..."/>
          <button onClick={HandleGiveStar} className='relative bg-subMain mt-4 text-white py-3 w-full flex-colo rounded'>
            Poster
            {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="30"
                    width="30"
                    color="#27ae60"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
          </button>
          {
            rate&&(
              <p className='text-green-600'>Bien envoyé,merci de votre participation</p>
            )
          }
          
        </div>
         <div className='col-span-3 flex flex-col gap-6'>
            <h3 className='text-xl text-text font-semibold'>Animateurs</h3>
            <div className='w-full flex flex-col bg-main gap-6 rounded-lg md:p-12 p-6 h-header overflow-y-scroll'>
              { 
                animateurs.map((user:any,index:any)=>(
                  <div className='md:grid flex flex-col w-full grid-cols-12 gap-6 bg-dry p-4 border border-gray-800 rounded-lg'>
                    <div className='col-span-2 bg-main md:block'>
                      <img src={`https://backend.zamandresyprod.mg/${user.profilePicturePath}`} alt={user.name} className='w-full h-24 rounded-lg object-cover'/>
                    </div>
                    <div className='col-span-7 flex flex-col gap-2'>
                      <h2> {user?.name} </h2>
                      <p className='text-xs leading-6 font-medium text-text'>
                        {truncateTextWithEllipsis(user?.about,50)}
                      </p>
                    </div>
                    <div className='col-span-3 flex-rows border-l border-border text-xs gap-1 text-star'>
                      <Rating value={user?.stars[1] || user?.stars[9] }/>
                    </div>
                  </div>
                ))
              }
            </div>
         </div>
      </div>
    </div>
  )
}

export default MovieRates