import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { backendURL } from '../Config/baseUrl'

interface artistState {
    loading: boolean,
    lists: Array<string>,
    error: string|null,
}
  export const GetAllartist = createAsyncThunk(
    'artist/all',
    async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/artists`,
          config
        )
        return data
      } catch (error:any) {
        return error.message
      }
    }
  )

  const initialState:artistState = {
    loading: false,
    lists:[],
    error:null
  }
  
  const artistSlice = createSlice({
    name: 'artist',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder)=>{
        builder.addCase(GetAllartist.pending, (state:artistState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(GetAllartist.fulfilled, (state:artistState,{ payload } :any) => {
            state.loading = false
            state.lists = payload
              
        })
        builder.addCase(GetAllartist.rejected, (state:artistState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
    }
  })    

export default artistSlice.reducer