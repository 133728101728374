import { RowsAnimator } from "./RowsAnimator"
const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"


function AnimatorTable({data,admin}:any) {
  return (
    <div className='overflow-x-scroll overflow-hidden relative w-full'>
        <table className='w-full table-auto border border-border divide-y divide-border'>
            <thead>
                <tr className='bg-dryGray'>
                    <th scope="col" className={`${Head}`}>
                        Photo
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Nom
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Contact
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Emission
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className='bg-main divide-y divide-gray-800'>
                    {data.map((animator:any,i:number)=>(
                        <RowsAnimator animator={animator} key={i} admin={admin}/>
                    ))}
            </tbody>
        </table>
    </div>
  )
}

export default AnimatorTable