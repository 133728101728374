export const UserData = [
    {
        name:"Charlot",
        image:"user1.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
    },
    {
        name:"Charlot",
        image:"user2.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"3.4",
    },
    {
        name:"Charlot",
        image:"user3.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"4",
    },
    {
        name:"Charlot",
        image:"user4.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"1",
    },
    {
        name:"Charlot",
        image:"user5.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"5",
    },
    {
        name:"Charlot",
        image:"user6.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"4",
    },
    {
        name:"Charlot",
        image:"user7.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"3.4",
    },
    {
        name:"Charlot",
        image:"user8.jpg",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"3.5",
    },
]

