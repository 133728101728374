import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../Store/Store';
import { SetPostFilter } from '../../../../../Toolkit/PostAction';

const pathsToHide = [
  '/dashboard/emission/starvan',
  '/dashboard/emission/manavatsava',
  '/dashboard/emission/fantsybobaka',
  '/dashboard/emission/live',
  '/dashboard/emission/dagolife',
  '/about-us',
  '/contact-us',
  '/dashboard',
  '/dashboard/register',
  '/dashboard/password',
  '/dashboard/quiz',
  '/dashboard/animators',
  '/dashboard/music',
  '/dashboard/emission',
  '/dashboard/teaser',
  '/dashboard/short',
  '/dashboard/event',
  '/politic',
];

const ElementToHide = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState('');

  const currentPath = window.location.pathname;

  const shouldHide = pathsToHide.some(path => currentPath.includes(path) || currentPath.match(/^\/watch\/\d+$/));

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(SetPostFilter(searchTerm));
  };

  return (
    <div className='col-span-3'>
      {shouldHide ? null : (
        <form className='w-full text-sm bg-dryGray rounded flex-btn gap-4' onSubmit={handleSubmit}>
          <button type="submit" className='bg-subMain w-12 flex-colo h-12 rounded text-white'>
            <FaSearch/>
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black'
            placeholder='Rechercher une publication'
          />
        </form>
      )}
    </div>
  );
};

export default ElementToHide;
