export const Emission = [
    {
        name:"Manavatsava",
        image:"manavatsava.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry"
    },
    {
        name:"Dago life",
        image:"dagolife.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"3.4",
    },
    {
        name:"Starvan",
        image:"starvan.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"4",
    },
    {
        name:"Fantsy bobaka",
        image:"fantsybobaka.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"1",
    },
    {
        name:"À la bonne heure",
        image:"game.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"5",
    },
    {
        name:"Starvan live",
        image:"starvanlive.png",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry",
        rate:"5",
    },
  
]

