import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { backendURL } from '../Config/baseUrl'

interface FavouriteState {
    loading: boolean,
    lists: Array<string>,
    error: string|null,
    message:string|null
}
  export const GetAllFavourite = createAsyncThunk(
    'Favourite/all',
    async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/favourites`,
          config
        )
        return data
      } catch (error:any) {
        return error.message
      }
    }
  )

  const initialState:FavouriteState = {
    loading: false,
    lists:[],
    error:null,
    message:null
  }
  
  const FavouriteSlice = createSlice({
    name: 'Favourite',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder)=>{
        builder.addCase(GetAllFavourite.pending, (state:FavouriteState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(GetAllFavourite.fulfilled, (state:FavouriteState,{ payload } :any) => {
            state.loading = false
            state.lists = payload
              
        })
        builder.addCase(GetAllFavourite.rejected, (state:FavouriteState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
    }
  })    

export default FavouriteSlice.reducer