import {useState} from 'react'
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { GoEye } from "react-icons/go";
import UpdateEmission from './Modals/Update/UpdateEmission';
import DeleteEmission from './Modals/Delete/DeleteEmission';

export default function RowsEmission ({emission,i,admin}:any){
    const [openModal,setOpenModal]=useState(false)
    const [openModalDelete,setOpenModalDelete]=useState(false)
    const [play,setPlay]=useState(false)
    const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"
    const Text = "text-sm text-left leading-6 whitespace-nowrap px-5 py-3"
    return(
        <>
            <UpdateEmission modalOpen={openModal} setModalOpen={setOpenModal} emission={emission}/>
            <DeleteEmission modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} emission={emission}/>
            <tr key={i}>
                <td className={`${Text}`}> 
                    <div className='w-12 h-12 p-1 bg-dry border border-border rounded '>
                    <img
                        className='h-full w-full  object-cover'
                        src={`https://backend.zamandresyprod.mg/${emission.logoPath}`}
                        alt="emi"
                    />
                    </div>
                </td>
                <td className={`${Text}`}>{emission.name.length > 9 ? emission.name.slice(0,10) + "...":emission.name}</td>
                <td className={`${Text} text-[12px] text-subMain font-bold`}>{emission.description.length > 15 ? emission.description.slice(0,25) + "...":emission.description}</td>
                <td className={`${Text} float-left flex-rows gap-2`}>
                    {admin ? (
                        <>
                            <button onClick={()=>setOpenModal(true)} className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Edit <FaEdit className='text-green-500'/>
                            </button>
                            <button onClick={()=>setOpenModalDelete(true)} className='bg-subMain text-white rounded flex-colo w-7 h-7'>
                                <MdDelete/>
                            </button>

                        </>
                    ):(

                        <>
                            <button className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Download <FaCloudDownloadAlt className='text-green-500'/>
                            </button>
                            <Link to={`/emissions/${emission?.name}`}>
                                <button className='bg-subMain text-white rounded flex-colo w-6 h-6'>
                                    <GoEye/>
                                </button>
                            </Link>
                        </>
                    )
                    }
                </td>
            </tr>
        </>
    )
}