import React, { useEffect } from 'react'
import { Autoplay } from 'swiper'
import {Swiper,SwiperSlide }from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../Store/Store'
import { GetAllsponsor } from '../../../Toolkit/SponsorsActions'

function Banner() {
    const message = useSelector((state:any)=>state.sponsor.message)
    const dispatch = useDispatch<AppDispatch>()
    const sponsors = useSelector((state:any)=>state.sponsor.lists)
  
    useEffect(()=>{
        dispatch(GetAllsponsor())
    },[message])
  return (
    <div className='relative'>
        <Swiper
            direction='horizontal'
            spaceBetween={1}
            slidesPerView={1}
            loop={true}
            speed={500}
            modules={[Autoplay]}
            autoplay={{delay:5000,disableOnInteraction:false}}
            className='xl:h-auto bg-dry lg:h-auto h-auto'
        >
         {sponsors && (sponsors.length>=1) && sponsors.map((sponsor:any,index:any)=>(
                <SwiperSlide key={index} className='relative rounded'>
                    <img src={`https://backend.zamandresyprod.mg/${sponsor.logo}`}
                    alt={sponsor.name}
                    className='w-full h-full object-cover'/>
                </SwiperSlide>
            ))
         }   
        </Swiper>
    </div>
  )
}

export default Banner