import React from 'react'
import { Link } from 'react-router-dom'
import { FaHeart } from 'react-icons/fa'
function Movie({movie}:any) {
  return (
    <div className='border border-border p-1 hover:scale-95 transitions relative rounded overflow-hidden'>
        <Link to={`/movies/${movie?.id}`} className='w-full'>
            <img src={`https://backend.zamandresyprod.mg/${movie.cover}`} alt={movie.legend} className='w-full h-64 object-cover'/>
        </Link>
        <div className='absolute flex-btn gap-2 bottom-0 right-0 left-0 bg-main bg-opacity-60 text-white px-4 py-3'>
            <h3 className='font-semibold truncate'>
                {movie.legend}
            </h3>
            <button className='h-9 w-9 text-sm flex-colo transitions hover:bg-transparent border border-subMain bg-subMain rounded-md text-white'>
                <FaHeart/>
            </button>
        </div>
    </div>  
  )
}

export default Movie