import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { backendURL } from '../Config/baseUrl'


export interface emission {
    id?:number,
    name: string,
    description : string,
    logo ?: File
}
interface emissionwithId {
  emiss:emission,
  id:number
}


interface emissionState {
    loading: boolean,
    lists: Array<emission>,
    error: string|null,
    message:string|null,
}
  export const GetAllemissions = createAsyncThunk(
    'emissions/all',
    async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const  {data} = await axios.get(
          `${backendURL}/tv_shows`,
          config
        )
        return data.tvShows
      } catch (error:any) {
        return error.message
      }
    }
  )
  export const Createemission = createAsyncThunk(
    'emissions/add',
    async (emissionInfo:emission,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': "multipart/form-data",
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.post(
            `${backendURL}/tv_show`,
            emissionInfo,
            config
          )
          return data
        } else {
          throw new Error("Le jéton est périmé,veuillez reconnecter");
          
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const EmissionUpdate = createAsyncThunk(
    'emissions/update',
    async (emissionInfo:emissionwithId,{ rejectWithValue }) => {
      try {
        if(localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': "multipart/form-data",
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.post(
            `${backendURL}/tv_show/update/${emissionInfo.id}`,
            emissionInfo.emiss,
            config
          )
          return data
        } else {
          throw new Error("Le jéton est périmé,veuillez vous reconnecter");
          
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )
  export const EmissionDelete = createAsyncThunk(
    'emissions/delete',
    async (id:number,{ rejectWithValue }) => {
      try {
        if (localStorage.getItem('adminToken')){
          const config = {
            headers: {
              'Content-Type': "multipart/form-data",
              'Authorization':  `Bearer ${localStorage.getItem('adminToken')}`,
            },
          }
          const  {data} = await axios.delete(
            `${backendURL}/tv_show/delete/${id}`,
            config
          )
          return data
        } else {
          throw new Error("Le jéton est périmé,veuillez vous reconnecter");
        }
        
      } catch (error:any) {
        return rejectWithValue(error.message)
      }
    }
  )

  const initialState:emissionState = {
    loading: false,
    lists:[],
    error:null,
    message:null,
  }
  
  const emissionSlice = createSlice({
    name: 'emission',
    initialState,
    reducers: {
      seterrornull: (state) => {
        state.error = null
      },
      setMessageNull: (state) => {
        state.message = null
      }
    },
    extraReducers: (builder)=>{
        builder.addCase(GetAllemissions.pending, (state:emissionState, { payload }:any) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(GetAllemissions.fulfilled, (state:emissionState,{ payload } :any) => {
            state.loading = false
            state.lists = payload
        })
        builder.addCase(GetAllemissions.rejected, (state:emissionState, { payload }:any) => {
            state.loading = false
            state.error = payload
        });
        builder.addCase(Createemission.pending, (state:emissionState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(Createemission.fulfilled, (state:emissionState,{ payload } :any) => {
            state.loading = false
            state.message = "Création de votre emission avec succèss"
        })
        builder.addCase(Createemission.rejected, (state:emissionState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
        builder.addCase(EmissionUpdate.pending, (state:emissionState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(EmissionUpdate.fulfilled, (state:emissionState,{ payload } :any) => {
            state.loading = false
            state.message = "Mise à jour de votre emission avec succèss"
        })
        builder.addCase(EmissionUpdate.rejected, (state:emissionState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
        builder.addCase(EmissionDelete.pending, (state:emissionState, { payload }:any) => {
          state.loading = true
          state.error = null
        })
        builder.addCase(EmissionDelete.fulfilled, (state:emissionState,{ payload } :any) => {
            state.loading = false
            state.message = "Suppression de votre emission avec succèss"
        })
        builder.addCase(EmissionDelete.rejected, (state:emissionState, { payload }:any) => {
            state.loading = false
            state.error = payload
        })
    }
  })    

export const {seterrornull,setMessageNull} = emissionSlice.actions
export default emissionSlice.reducer