import React from 'react'
import DeleteQuestion from './Modals/Delete/DeleteQuestion'
import { MdDelete } from 'react-icons/md'
import { useState } from 'react'

import { parseArrayFromString } from '../../../Config/AlgoInput'


export default function RowsQuestion({question}:any) {
    const [deletequestion,setDeletequestion]  = useState(false)
  return (
    <>
    <DeleteQuestion modalOpen={deletequestion} setModalOpen={setDeletequestion} question={question} />
    <div className='relative bg-white min-h-[200px] max-h-[200px] w-full sm:w-auto rounded-md p-8 overflow-scroll'>
      <MdDelete onClick={()=>setDeletequestion(true)} title="supprimer cette question" className='cursor-pointer bg-subMain text-white rounded flex-colo w-7 h-7 sticky right-1 top-0 mb-2'/>
        <div className='bg-gradient-to-r from-dry to-pink-500 min-h-[250px] rounded-md'>
            <div className="container mx-auto px-4">
            <h4 className='text-white font-bold italic pb-2'>{question.sentence}</h4>
            <ul className="grid grid-cols-1 gap-4">
                <li
                    className="flex items-center bg-green-300 p-4 rounded-md h-12"
                >
                    <div className="text-main text-left truncate">{question.correctAnswer}</div>
                </li>
                
                {parseArrayFromString(question.possibleAnswer).map((pa:any,index:number)=>(
                <li
                    key={index}
                    className="flex items-center bg-red-300 p-4 rounded-md h-12"
                >
                    <div className="text-main text-left truncate">{pa}</div>
                </li>
                ))}
            </ul>
            </div>
        </div>
    </div>
    </>
  )
}

