import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import { Input, Message, Select } from '../Components/UsedInput'
import Uploader from '../Components/Uploader'
import { CategoriesData } from '../../../Data/CategoriesData'
import { UserData } from '../../../Data/UserData'
import { MdDelete, MdUpload } from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import CastsModal from '../Components/Modals/CastsModal'

function AddMovie() {
     const [modalOpen,setModalOpen]=useState(false)
     const [cast,setCast]=useState(null)
  return (
    <Sidebar>
        <CastsModal modalOpen={modalOpen} setModalOpen={setModalOpen} cast={cast} />
        <div className='flex flex-col gap-6'>
            <h2 className='text-xl font-bold'>Add Movie</h2>
            <div className='w-full grid md:grid-cols-2  gap-6'>
                <Input
                    label="Movie title"
                    placeholder="cama"
                    type="text" 
                    bg={true}
                />
                <Input
                    label="Hours"
                    placeholder="2hr"
                    type="text" 
                    bg={true}
                />
            </div>
            <div className='w-full  grid md:grid-cols-2 gap-6'>
                <Input
                    label="Language Used"
                    placeholder="English"
                    type="text" 
                    bg={true}
                />
                <Input
                    label="Year of release"
                    placeholder="12-03-2000"
                    type="number"
                    bg={true}
                />
            </div>
            <div className='w-full grid md:grid-cols-2 gap-6'>
                <div className='flex flex-col gap-2'>
                    <p className='text-border font-semibold text-sm'>
                        Image without title
                    </p>
                    <Uploader/>
                    <div className='w-32 h-32 p-2 bg-main border border-border rounded'>
                        <img 
                        src="/assets/images/user1.jpg"
                        alt=""
                        className='w-full h-full object-cover rounded'
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <p className='text-border font-semibold text-sm'>
                        Image with title
                    </p>
                    <Uploader/>
                    <div className='w-32 h-32 p-2 bg-main border border-border rounded'>
                        <img 
                        src="/assets/images/user2.jpg"
                        alt=""
                        className='w-full h-full object-cover rounded'
                        />
                    </div>
                </div>
            </div>
            <Message
            labe="Movie Description"
            placeholder="Make it short and sweet"
            />
            <div className='text-sm w-full'>
                <Select
                label="Movie category"
                options={CategoriesData}
                />
            </div>
            <div className='flex flex-col gap-2'>
                <p className='text-border font-semibold text-sm'> 
                    Movie video
                </p>
                <Uploader/>
            </div>
            <div className='w-full grid lg:grid-cols-2 gap-6 items-start'>
                <button onClick={()=>setModalOpen(true)} className='w-full py-4 bg-main border border-subMain border-dashed text-white roudned'>
                    Add Cast
                </button>
                <div className='grid 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-4 grid-cols-2 gap-4'>
                    {
                        UserData.map((user,i)=>(
                            <div key={i} className='p-2 italic text-xs text-text rounded flex-colo bg-main border border-border'>
                                <img 
                                src={`/assets/images/${user.image? user.image:"user3.jpg"}`}
                                alt={user.name}
                                className='w-full h-24 object-cover rounded mb-2'
                                />
                                <p>
                                  {user.name}  
                                </p>
                                <div className='flex-rows mt-2 w-full gap-2'>
                                    <button className='w-6 h-6 bg-dry flex-colo border border-border text-subMain rounded'>
                                        <MdDelete/>
                                    </button>
                                    <button className='w-6 h-6 bg-dry flex-colo border border-border text-green-600 rounded'>
                                        <FaEdit/>
                                    </button>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
             <div className='flex justify-end items-center my-4'> 
                <button className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                  <MdUpload/>  Publish Movie
                </button>
             </div>
        </div>  
    </Sidebar>
  )
}

export default AddMovie