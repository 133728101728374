import React, { useEffect } from 'react'
import { Autoplay } from 'swiper'
import {Swiper,SwiperSlide }from 'swiper/react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllposts } from '../../../Toolkit/PostAction'
import { AppDispatch } from '../../../Store/Store'

function Banner() {
    const message = useSelector((state:any)=>state.post.message)
    const posts = useSelector((state:any)=>state.post.lists)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(()=>{
        dispatch(GetAllposts())
    },[message])
  return (
    <div className='relative w-full'>
        <Swiper
            direction='vertical'
            spaceBetween={1}
            slidesPerView={1}
            loop={true}
            speed={1000}
            modules={[Autoplay]}
            autoplay={{delay:5000,disableOnInteraction:false}}
            className='w-full xl:h-96 bg-dry lg:h64 h-64'
        >
         {posts && posts.slice(0,6).map((movie:any,index:any)=>(
                <SwiperSlide key={index} className='relative rounded overflow-hidden'>
                    <img src={`https://backend.zamandresyprod.mg/${movie.cover}`}
                    alt={movie.legend}
                    className='w-full h-full object-cover'/>
                    <div className='absolute linear-bg xl:pl-52 sm:pl-32 pl-8 top-0 bottom-0 left-0 right-0 w-full flex flex-col justify-center lg:gap-8 md:gap-5 gap-4'>
                        <div className='relative w-4/5 p-4 rounded-md flex justify-between'>
                            <div className='absolute top-0 left-0 w-full h-full backdrop-blur-lg bg-opacity-50 bg-dry rounded-2xl'></div>
                            <h1 className='xl:text-4xl truncate capitalize font-sans sm:text-2xl text-xl  z-10 relative text-[#ffd60a] font-thin'>
                                {movie.legend}
                            </h1>
                            <div className='flex gap-5 items-center text-dryGray z-10 relative'>
                                {/* Your content */}
                            </div>
                            <div className='flex gap-5 items-center z-10 relative'>
                                {/* Your content */}
                            </div>
                            <div className='flex gap-5 items-center z-10 relative'>
                                <Link
                                to={`/movies/${movie?.id}`}
                                className='bg-subMain hover:text-main transitions text-white px-8 py-3 rounded font-medium sm:text-sm text-xs'
                                >
                                Regarder
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))
         }   
        </Swiper>
    </div>
  )
}

export default Banner
