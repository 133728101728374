import React from 'react';
import Layout from '../Layout/Layout';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout>
        <div className="bg-main text-white min-h-screen flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
            <div className="w-full mx-auto">
                <div className=" rounded-lg px-8 py-6">
                <div className="max-w-4xl mx-auto p-6">
                    <h1 className="text-2xl font-bold mb-4">POLITIQUE DE CONFIDENTIALITE</h1>
                    <p><strong>Za Mandresy apk</strong></p>
                    <p><em>Type site : application</em></p>

                    <div className="mt-4">
                        <h2 className="text-lg font-semibold mb-2 text-subMain">Le but de cette politique de confidentialité</h2>
                        <p>Le but de cette politique est d’informer les utilisateurs de notre site des données personnelles que nous recueillons ainsi que les informations suivantes, le cas échéant :</p>
                        <ol className="list-decimal ml-6 mt-2">
                        <li>Les données personnelles que nous recueillerons</li>
                        <li>L’utilisation des données recueillies</li>
                        <li>Qui a accès aux données recueillies</li>
                        <li>Les droits d’utilisateurs du site</li>
                        <li>La politique de cookies du site</li>
                        </ol>
                        <p className='mt-2 text-yellow-400'>Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de notre site.</p>
                    </div>

                    <div className="mt-4">
                        <div className="mt-4">
                            <h2 className="text-lg font-semibold mb-2">Lois applicables</h2>
                            <p>Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux règlements suivants :</p>
                            <ul className="list-disc ml-6 mt-2">
                                <li>Traitées de manière licite, loyale et transparente au regard de la personne concernée (licité, loyauté, transparence);</li>
                                <li>Collectées pour des finalités déterminées, explicites et légitimes et ne pas être traitées ultérieurement d’une manière incompatible avec ses finalités, le traitement ultérieur à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n’est pas considéré, conformément à l’article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités);</li>
                                <li>Adéquates pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données);</li>
                                <li>Exactes et si nécessaire, tenues à jour, toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitudes);</li>
                                <li>Conservées sous une forme permettant l’identification des personnes concernées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l’article 89 paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conversation);</li>
                                <li>Traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d’origine accidentelle, à l’aide des mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).</li>
                            </ul>
                            </div>
                            <div className="mt-4">
                            <p>Le traitement n’est illicite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
                            <ol className="list-decimal ml-6 mt-2">
                                <li>La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques;</li>
                                <li>Le traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée est partie ou a l’exécution des mesures précontractuelles prises à la demande de celle-ci;</li>
                                <li>Le traitement est nécessaire au respect d’une obligation légale à laquelle le responsable du traitement est soumis;</li>
                                <li>Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d’une autre personne physique;</li>
                                <li>Le traitement est nécessaire à l’exécution d’une mission d’intérêt public ou relevant de l’exercice de l’autorité publique dont est investi le responsable du traitement;</li>
                                <li>Traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d’origine accidentelle, à l’aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).</li>
                            </ol>
                            </div>
                            <div className="mt-4">
                                <p>Le traitement n’est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
                                <ol className="list-decimal ml-6 mt-2">
                                    <li>La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques;</li>
                                    <li>Le traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée est partie ou à l’exécution de mesures précontractuelles prises à la demande de celle-ci;</li>
                                    <li>Le traitement est nécessaire au respect d’une obligation légale à laquelle le responsable du traitement est soumis;</li>
                                    <li>Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d’une autre personne physique;</li>
                                    <li>Le traitement est nécessaire à l’exécution d’une mission d’intérêt public ou relevant de l’exercice de l’autorité publique dont est investi le responsable du traitement;</li>
                                    <li>Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droit fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</li>
                                </ol>
                            </div>

                            <div className="mt-4">
                                    <p>Pour les résidents de l'État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). En cas d'incohérences entre ce document et la CCPA, la législation de l'État s'appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer aux lois pertinentes.</p>
                            </div>

                            <div className="mt-4">
                                <h2 className='text-subMain'>Consentement</h2>
                                <p>Les utilisateurs conviennent qu'en utilisant notre site, ils consentent à :</p>
                                <ul className="list-disc list-inside">
                                    <li>Les conditions énoncées dans la présente politique de confidentialité</li>
                                    <li>La collecte, l'utilisation et la conservation des données énumérées dans la présente politique</li>
                                </ul>
                            </div>

                            <div className="mt-4">
                                <h2>Données personnelles que nous collectons</h2>
                                <h3>Données collectées automatiquement</h3>
                                <p>Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :</p>
                                <ul className="list-disc list-inside">
                                    <li>Adresse IP</li>
                                    <li>Lieu</li>
                                    <li>Liens sur lesquels un utilisateur clique tout en utilisant le site</li>
                                    <li>Contenu que l'utilisateur consulte sur votre site</li>
                                </ul>
                            </div>

                            <div className="mt-4">
                                <h3>Données recueillies de façon non automatique</h3>
                                <p>Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site :</p>
                                <ul className="list-disc list-inside">
                                    <li>Prénom et nom</li>
                                    <li>Âge</li>
                                    <li>Date de naissance</li>
                                    <li>Sexe</li>
                                    <li>Email</li>
                                    <li>Numéro de téléphone</li>
                                    <li>Domicile</li>
                                    <li>Information de paiement</li>
                                    <li>Données de remplissage automatique</li>
                                </ul>
                                <p>Ces données peuvent être recueillies au moyen des méthodes suivantes : ………………….</p>
                                <p>Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l'objectif énoncé dans cette politique de confidentialité.</p>
                                <p>Nous ne recueillons pas de données supplémentaires sans vous en informer au préalable.</p>
                            </div>

                            <div className="mt-4">
                                <h2>Comment nous utilisons les données personnelles</h2>
                                <p>Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur la page pertinente de notre site. Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons.</p>
                                <p>Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :</p>
                                <ul className="list-disc list-inside">
                                    <li>………Cookies…………………….</li>
                                </ul>
                                <p>Les données que nous recueillons lorsque l'utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :</p>
                                <ul className="list-disc list-inside">
                                    <li>Une…Notification push………………………….</li>
                                </ul>
                            </div>

                            <div className="mt-4">
                                <h2>Avec qui nous partageons les données personnelles</h2>
                                <h3>Employés</h3>
                                <p>Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.</p>
                                <h3>Tierces parties</h3>
                                <p>Nous pouvons partager les données utilisateur avec les tiers suivants :</p>
                                <p>Nous pouvons partager les données utilisateur avec les tiers aux fins suivantes :</p>
                                <p>Les tiers ne seront pas en mesure d'accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l'objectif donné.</p>
                            </div>

                            <div className="mt-4">
                                <h2>Autres divulgations</h2>
                                <p>Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf les cas suivants :</p>
                                <ul className="list-disc list-inside">
                                    <li>Si la loi l'exige</li>
                                    <li>Si elle est requise pour toute procédure judiciaire</li>
                                    <li>Pour prouver ou protéger nos droits légaux</li>
                                    <li>A des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la vendre</li>
                                </ul>
                                <p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n'avons pas de contrôle sur leurs politiques et pratiques de confidentialité.</p>
                            </div>

                            <div className="mt-4">
                                <h2>Combien de temps nous stockons les données personnelles</h2>
                                <p>Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.</p>
                            </div>

                            <div className="mt-4">
                                <h2>Comment nous protégeons vos données personnelles</h2>
                                <p>Alors que nous prenons toutes les précautions raisonnables pour vous assurer que nos données d'utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours des risques de préjudice. L'internet dans sa totalité peut être parfois peu sûr, et donc nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-subMain">Vos droits en tant qu'utilisateur</h2>
                            <p>Conformément au RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :</p>
                            <ul className="list-disc list-inside">
                            <li>Droit d'accès</li>
                            <li>Droit de rectification</li>
                            <li>Droit à l'effacement</li>
                            <li>Droit de restreindre le traitement</li>
                            <li>Droit à la portabilité des données</li>
                            <li>Droit d'objection</li>
                            </ul>
                            <p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.</p>
                        </div>
                    <div className='mt-4'>
                    Comment modifier, supprimer ou contester les données recueillies
                    Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée ici : ………………….
                    </div>
                    <div className='mt-4'>
                    Politiques sur les cookies
                    Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.
                    </div>
                    <div className="mt-4">
                            <h2 className="text-subMain">Nous utilisons les cookies suivants notre site :</h2>
                            
                            <ul className="list-disc list-inside">
                            <li>Cookies fonctionnels
                                Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites
                            </li>
                            <li>Cookies analytiques 
                                Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.
                            </li>
                            
                            </ul>
                            <p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.</p>
                        </div>

                    <div className='mt-4'>
                    Vous pouvez choisir d’être averti à chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation
                    </div>
                    <div className="mt-4">
                        <h2 className="text-subMain">Cookies tiers</h2>
                        <p>
                        Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :
                        </p>
                        <ol className="list-decimal ml-6 mt-2">
                        <li>Communication commerciale………………</li>
                        </ol>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-subMain">Dispositions supplémentaires</h2>
                        <ol className="list-decimal ml-6 mt-2">
                            <li>………………</li>
                        </ol>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-subMain">Modifications</h2>
                        <p>
                            Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.
                        </p>
                    </div>
                    <div className="mt-4">
                        <h2 className="text-subMain">Contact</h2>
                        <p>
                            Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :
                            <a href="mailto:Mandresy.andria21@gmail.com" className="text-blue-500 hover:underline">Mandresy.andria21@gmail.com</a>
                        </p>
                        <p className="mt-2">Date d’entrée en vigueur : le ……………………..</p>
                    </div>
                    <p className="mt-4">Date d'entrée en vigueur : le ...</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
