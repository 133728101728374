import {useState} from 'react'
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { GoEye } from "react-icons/go";
import UpdateShortComponent from './Modals/Update/updateShort';
import DeleteShort from './Modals/Delete/DeleteShort';

export default function RowsShort ({short,i,admin}:any){
    const [openModal,setOpenModal]=useState(false)
    const [openModalDelete,setOpenModalDelete]=useState(false)
    const [play,setPlay]=useState(false)
    const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"
    const Text = "text-sm text-left leading-6 whitespace-nowrap px-5 py-3"
    return(
        <>
            <UpdateShortComponent modalOpen={openModal} setModalOpen={setOpenModal} sho={short} />
            <DeleteShort modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} short={short}/>
            <tr key={i}>
                <td className={`${Text}`}> 
                    <div className='w-12 p-1 bg-dry border border-border h-12 rounded overflow-hidden'>
                        <video autoPlay={play}  controls className='w-full h-full rounded'>
                            <source src={`https://backend.zamandresyprod.mg/${short.post.files[0]}`} type="video/mp4" title="titre"/>
                        </video>
                    </div>
                </td>
                <td className={`${Text}`}>{short.title.length > 15 ? short.title.slice(0,15) + "...":short.title}</td>
                <td className={`${Text}`}>{short.post.legend.length > 15 ? short.post.legend.slice(0,20) + "...":short.post.legend}</td>
                <td className={`${Text}`}>{short.post.created_at}</td>
                <td className={`${Text} float-left flex-rows gap-2`}>
                    {admin ? (
                        <>
                            <button onClick={()=>setOpenModal(true)} className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Edit <FaEdit className='text-green-500'/>
                            </button>
                            <button onClick={()=>setOpenModalDelete(true)} className='bg-subMain text-white rounded flex-colo w-7 h-7'>
                                <MdDelete/>
                            </button>

                        </>
                    ):(

                        <>
                            <button className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Download <FaCloudDownloadAlt className='text-green-500'/>
                            </button>
                            <Link to={`/shorts/${short?.name}`}>
                                <button className='bg-subMain text-white rounded flex-colo w-6 h-6'>
                                    <GoEye/>
                                </button>
                            </Link>
                        </>
                    )
                    }
                </td>
            </tr>
        </>
    )
}