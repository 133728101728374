import {useState} from 'react'
import { FaCloudDownloadAlt, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { GoEye } from "react-icons/go";
import DeleteTeaser from './Modals/Delete/DeleteTeaser';
import UpdateTeaser from './Modals/Update/UpdateTeaser';


export default function RowsTeaser ({teaser,i,admin}:any){
    console.log(teaser.image);
    
    const [openModal,setOpenModal]=useState(false)
    const [openModalDelete,setOpenModalDelete]=useState(false)
    const [play,setPlay]=useState(false)
    const Text = "text-sm text-left leading-6 whitespace-nowrap px-5 py-3"
    return(
        <>
            <UpdateTeaser modalOpen={openModal} setModalOpen={setOpenModal} teaser={teaser} />
            <DeleteTeaser modalOpen={openModalDelete} setModalOpen={setOpenModalDelete} teaser={teaser}/>
            <tr key={i}>
                <td className={`${Text}`}> 
                    {teaser.image ? 
                    <div className='w-12 h-12 p-1 bg-dry border border-border rounded text-[1px]'>
                        <img
                            className='h-full w-full  object-cover'
                            src={`https://backend.zamandresyprod.mg/${teaser.image}`}
                            alt="event"
                        />
                    </div>
                    :
                    <div className='w-12 h-12 p-1 bg-dry border border-border rounded text-[1px]'>
                        <video autoPlay={play}  controls className='w-full h-full rounded'>
                            <source src={`https://backend.zamandresyprod.mg/${teaser.post.files[0]}`} type="video/mp4" title="titre"/>
                        </video>
                    </div>
                }
                </td>
                <td className={`${Text}`}>{teaser.title.length > 9 ? teaser.title.slice(0,10) + "...":teaser.title}</td>
                <td className={`${Text} text-[12px] text-subMain font-bold`}>{teaser.date}</td>
                <td className={`${Text}`}>{teaser.place.length > 9 ? teaser.place.slice(0,10) + "...":teaser.place}</td>
                <td className={`${Text}`}>{teaser.post.created_at}</td>
                <td className={`${Text} float-left flex-rows gap-2`}>
                    {admin ? (
                        <>
                            <button onClick={()=>setOpenModal(true)} className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Edit <FaEdit className='text-green-500'/>
                            </button>
                            <button onClick={()=>setOpenModalDelete(true)} className='bg-subMain text-white rounded flex-colo w-7 h-7'>
                                <MdDelete/>
                            </button>

                        </>
                    ):(

                        <>
                            <button className='border border-border bg-dry flex-rows gap-2 text-white rounded py-1 px-2'>
                                Download <FaCloudDownloadAlt className='text-green-500'/>
                            </button>
                            <Link to={`/teasers/${teaser?.name}`}>
                                <button className='bg-subMain text-white rounded flex-colo w-6 h-6'>
                                    <GoEye/>
                                </button>
                            </Link>
                        </>
                    )
                    }
                </td>
            </tr>
        </>
    )
}