import RowsQuiz from "./RowsQuiz"
import { sortByCreatedAtDescending } from "../../../Data/QuizType"


const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"

function TableQuiz({data}:any) {
   
  return (
    <div className='overflow-x-hidden relative w-full h-[400px] overflow-y-auto'>
        <table className='w-full table-auto border border-border divide-y divide-border'>
            <thead>
                <tr className='bg-dryGray'>
                    <th scope="col" className={`${Head}`}>
                        Titre
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Date de publication
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Nombre de participant
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className='bg-main divide-y divide-gray-800'>
                {sortByCreatedAtDescending(data).map((quiz:any,i:number)=>(
                    <RowsQuiz quiz={quiz} key={i} admin={true}/>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default TableQuiz