import React, { useEffect, useState } from 'react'
import Titles from '../Components/Titles'
import { BsBookmarkStarFill,BsCaretLeftFill,BsCaretRightFill } from 'react-icons/bs'
import {Swiper, SwiperSlide} from 'swiper/react'
import { Autoplay,Navigation } from 'swiper'
import { Animateurs } from '../../../Data/AnimateurData'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Rating from '../Components/Stars'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../Store/Store'
import { GetAllAnimators } from '../../../Toolkit/AnimatorAction'

function TopRated() {
   const [nextEl,setNextEl]=useState(null)
   const [prevEl,setPrevEl]= useState(null) 
   const message = useSelector((state:any)=>state.animator.message)
   const dispatch = useDispatch<AppDispatch>()
   const classNames = "hover:bg-gray-500 transitions text-sm rounded w-8 h-8 flex-colo bg-subMain text-white"
   const animators = useSelector((state:any)=>state.animator.lists)
  
  useEffect(()=>{
    dispatch(GetAllAnimators())
  },[message])
  return (
    <div className='my-16'>
       <Titles title="Nos animateurs" Icon={BsBookmarkStarFill}/>
       <div className='mt-10'>
           <Swiper
           navigation={{nextEl,prevEl}}
           autoplay={true}
           speed={1000}
           loop={true}
           modules={[
               Navigation,Autoplay
           ]}
           breakpoints={{
            0:{
                slidesPerView:1,
                spaceBetween:10
            },
            768:{
                slidesPerView:2,
                spaceBetween:20
            },
            1024:{
                slidesPerView:3,
                spaceBetween:30
            },
            1280:{
                slidesPerView:4,
                spaceBetween:40
            }
        }}
           >
               {animators && animators.map((animator:any,index:number)=>(
                   <SwiperSlide key={index}>
                       <div className='p-4 h-rate hovered border-border bg-dry rounded-lg overflow-hidden'>
                           <img src={`https://backend.zamandresyprod.mg/${animator.profilePicturePath}`} alt={animator.name} className='w-full h-full object-cover rounded-lg'/>
                           <div className='px-4 gap-6 hoveres text-center absolute bg-black bg-opacity-70 top-0 right-0 left-0 bottom-0 '>
                               <button className='w-12 h-12 flex-colo transitions hover:big-subMain rounded-full bg-white bg-opacity-30 text-white'>
                                   <FaHeart/>
                               </button>
                               <Link 
                               className="font-semibold text-xl truncate line-champ-2"
                               to={`assets/images/${animator.name}`}
                               >  
                                   {animator.name}
                               </Link>
                               <div className='flex gap-2  text-star'>
                                   <Rating value={animator.stars['9'] || animator.stars['1'] || animator.stars['8']}/>
                               </div>
                           </div>
                       </div>
                   </SwiperSlide>
               ))}
           </Swiper>
           <div className='w-full px-1 flex-rows gap-6 pt-12'>
               <button className={classNames} ref={(node:any)=>setPrevEl(node)}>
                   <BsCaretLeftFill/>
               </button>
               <button className={classNames} ref={(node:any)=>setNextEl(node)}>
                   <BsCaretRightFill/>
               </button>
           </div>
       </div>
    </div>
  )
}

export default TopRated