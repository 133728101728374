import React,{useState,ChangeEvent} from 'react'
import MainModal from '../Modals/MainModal'
import {  MdUpload } from 'react-icons/md'
import { BsImage } from 'react-icons/bs'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CirclesWithBar } from  'react-loader-spinner'
import * as yup from "yup";
import convertirDate from '../../../../Config/FormatDate'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../Store/Store'
import { Createevent } from '../../../../Toolkit/EventAction'
import { useNavigate } from 'react-router-dom'

const options = [
    {
        title :"OUI",
        value : true
    },
    {
        title :"NON",
        value : false
    }
]
const schema = yup.object({
    legend:yup.string().required(),
    file1:yup.mixed().label('Votre photo').required(),
    date: yup.date().required("La date est important"),
    place: yup.string().required(),
    ticketPlace: yup.string().required(),
    important: yup.boolean().required()
  }).required();
  type FormData = yup.InferType<typeof schema>;

function CreateEvent({modalOpen,setModalOpen}:any) {
    const navigate = useNavigate()
    const loading = useSelector((state:any)=>state.event.loading)
    const dispatch = useDispatch<AppDispatch>()
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<FormData>({
        resolver: yupResolver(schema),
      });
    const [link,setLink]= useState('')
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setLink(URL.createObjectURL(e.target.files[0]))
        }
      };
    const onSubmit = async(data:FormData)=>{
        const photo = (data.file1 as any[])[0];
        try {
            if(photo){
                const formData = new FormData();
                formData.append('file1', photo);
                const { legend, place ,ticketPlace,important, ...restData} = data;
                const date = convertirDate(data.date)
                const datafinal = { legend, place ,ticketPlace,important,date, ...Object.fromEntries(formData)};
                await dispatch(Createevent(datafinal)).then((res)=>{
                    setLink('')
                    reset()
                    setModalOpen(false)
                    return res
                })
            }else{
                return 1
            }
        } catch (error) {
            return error
        }
    }
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className='inline-block sm:w-4/5 md:w-4/5 lg:w-4/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl'>
      {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="100"
                    width="100"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
        <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-6'>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Description</label>
                        <input 
                        {...register("legend")}
                        name="legend"
                        type="text"
                        placeholder="Description de votre événement..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.legend?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">L'endroit exacte de l'évenement</label>
                        <input 
                        {...register("place")}
                        name="place"
                        type="text"
                        placeholder="Lieu..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.place?.message}</p>
                    </div>
                </div>
                <div className='w-full  grid md:grid-cols-2 gap-6'>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Date de l'évenement</label>
                        <input 
                        {...register("date")}
                        name="date"
                        type="date"
                        placeholder="Date..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.date?.message}</p>
                    </div>
                    <div className="text-sm w-full">
                        <label className="text-border font-semibold">Ticket place de l'évenement</label>
                        <input 
                        {...register("ticketPlace")}
                        name="ticketPlace"
                        type="text"
                        placeholder="SuperMusic Analakely..."
                        className={`w-full  text-sm  mt-2 p-5 border border-border rounded text-white bg-dry`}/>
                        <p className="text-red-600 text-left">{errors.ticketPlace?.message}</p>
                    </div>
                </div>
                <div className='w-full grid md:grid-cols-2 gap-6'>
                    <div className='text-sm w-full'>
                        <label className="text-subMain font-semibold">Important ?</label>
                        <select {...register('important')} name="important" className="w-full h-16 mt-2 px-6 py-4 text-text bg-main border border-border rounded">
                            {options.map((option:any,i:number)=>(
                                <option key={i} value={option.value}> 
                                    {option.title}
                                </option>
                            ))}
                        </select>
                        <p className="text-red-600 text-left">{errors.important?.message}</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                                <p className='text-border font-semibold text-sm'>
                                    Votre photo
                                </p>
                                <div className='w-full text-center'>
                                        <div className='w-full'>
                                            <label
                                                className="flex justify-center h-16  transition bg-main border-2 px-6 py-4  border-border border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                                <span className="flex items-center space-x-2">
                                                    <BsImage className='text-subMain h-16 w-16'/>
                                                    <span className="font-medium text-white text-center">
                                                        Ajouter un photo a votre publication
                                                    </span>
                                                </span>
                                                <input {...register('file1',{
                                                onChange: handleFileChange})} 
                                                accept='image/*'
                                                type="file" name="file1" className="opacity-0"/>
                                            </label>
                                        </div>
                                </div>
                                <div className='w-32 h-32 p-2 bg-main border border-border rounded'>
                                    <img 
                                    src={link}
                                    alt=""
                                    className='w-full h-full object-cover rounded'
                                    />
                                </div>
                                <p className="text-red-600 text-left">{errors.file1?.message}</p>
                            </div>
                </div>
                <div className='flex justify-end items-center my-4'> 
                    <button type="submit" className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                    <MdUpload/>  Publier l'annonce
                    </button>
                </div>
            </div>  
        </form>
       </div>
    </MainModal>
  )
}

export default CreateEvent