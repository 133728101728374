import RowsEvent from './RowsEvent';

const Head = "text-xs text-left text-main font-semibold px-6 py-2 uppercase"

function TableEvent({data,admin}:any) {
    
  return (
    <div className='overflow-x-hidden relative w-full h-[400px] overflow-y-auto'>
        <table className='w-full table-auto border border-border divide-y divide-border'>
            <thead>
                <tr className='bg-dryGray'>
                    <th scope="col" className={`${Head}`}>
                        Affiche
                    </th>
                    <th scope="col" className={`${Head}`}>
                        TicketPlace
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Date
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Place
                    </th>
                    <th scope="col" className={`${Head}`}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className='bg-main divide-y divide-gray-800'>
                {data.map((event:any,i:number)=>(
                    <RowsEvent event={event} key={i} admin={admin}/>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default TableEvent