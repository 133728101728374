import React,{useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { BiArrowBack } from 'react-icons/bi';
import { FaPlay } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { GetAllposts } from '../../../Toolkit/PostAction';
import { AppDispatch } from '../../../Store/Store';
import Layout from '../Layout/Layout';

function WatchPage() {
    let {id}=useParams()
    const [play,setPlay]=useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const posts = useSelector((state:any)=>state.post.lists)
    const message = useSelector((state:any)=>state.post.message)
    const movie= posts.find((post:any)=>post.id == id)

    useEffect(()=>{
        dispatch(GetAllposts())
    },[message])

  return (
    <Layout>
        <div className='container mx-auto bg-dry p-6 mb-12 overflow-hidden'>
            <div className='flex-btn flex-wrap mb-6 gap-2 bg-main rounded border-gray-800 p-6'>
                <Link to={`/movies/${movie?.id}`} className='md:text-xl text-sm flex gap-3 items-center font-bold text-dryGray'>
                    <BiArrowBack/> {movie?.legend}
                </Link>
                
            </div>
            {
                play ? (
                    <div className="flex flex-col justify-center">
                        <div className='rounded-lg justify-center flex'>
                            <ReactPlayer
                                url={`https://backend.zamandresyprod.mg/${movie.files[0]}`}
                                playing={play}
                                controls
                                className="rounded-lg shadow-lg"
                                width="auto"
                                height={"400px"}
                            />
                        </div>
                    </div>
                ):(
                    <div className='w-full h-screen rounded-lg overflow-hidden relative'> 
                        <div className='absolute top-0 left-0 bottom-0 right-0 bg-main bg-opacity-30 flex-colo'>
                            <button onClick={()=>setPlay(true)} className='bg-white text-subMain flex-colo border border-subMain rounded-full w-20 h-20 font-medium text-xl'>
                                <FaPlay/>
                            </button>
                        </div>
                        <img src={movie?.cover ? `https://backend.zamandresyprod.mg/${movie.cover}`:"/assets/images/user1.jpg"} alt={movie?.name} className="w-full object-cover rounded-lg"/>
                    </div>
                )
            }
        </div>
    </Layout>
  )
}

export default WatchPage