import React,{useState,ChangeEvent, useEffect} from 'react'
import { ImPlay } from "react-icons/im";
import { BsImage } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AppDispatch } from '../../../../../Store/Store';
import { CirclesWithBar } from  'react-loader-spinner'
import { Createpost, seterrornull } from '../../../../../Toolkit/PostAction';

import MainModal from '../MainModal'
import {  MdUpload } from 'react-icons/md'
import { GetAllemissions } from '../../../../../Toolkit/EmissionAction';


const schema = yup.object({
  files1: yup.mixed().label('Votre photo'),
  files2: yup.mixed().label('Votre photo'),
  legend: yup.string().required(),
}).required();
type FormData = yup.InferType<typeof schema>;

function Createemission({modalOpen,setModalOpen,id}:any) {
  const error = useSelector((state:any)=>state.post.error)
  const loading = useSelector((state:any)=>state.post.loading)
  const dispatch = useDispatch<AppDispatch>()
  const [play,setPlay]=useState(false)
  const [link,setLink]= useState('')
  const [videolink,setvideolink]=useState('')


  const {
    register,
    handleSubmit,reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLink(URL.createObjectURL(e.target.files[0]))
    }
  };
  const handleFileChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setvideolink(URL.createObjectURL(e.target.files[0]))
    }
  };

  const HandlePost = async(data:FormData)=>{
    try {
     const video = (data.files2 as any[])[0];
     const photo = (data.files1 as any[])[0];
     if (video && photo) {
       const formData = new FormData();
       formData.append('cover', photo);
       formData.append('file', video);
       const { legend, ...restData} = data;
       const datafinal = { legend,tvShow:id,...Object.fromEntries(formData)};
       dispatch(Createpost(datafinal)).then((res:any)=>{
          setModalOpen(false)
          reset()
          setLink("")
          setvideolink("")
          return res  
       })
     } 
    } catch (error) {
     throw new Error("Une erreur a été survenu");
    }
   }
  return (
    <>
        <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className='inline-block sm:w-2/5 md:w-2/5 lg:w-2/5 w-full align-middle p-10 overflow-y-auto h-full border border-border bg-main text-white  rounded-2xl text-left'>
            {loading&&(
                  <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
                    <CirclesWithBar
                    height="100"
                    width="100"
                    color="#E90064"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  />
                </div>
            )}
            <form className='w-full' onSubmit={handleSubmit(HandlePost)}>
                <div className="text-sm w-full">
                        <label className="text-border font-semibold">Description de votre publication</label> 
                        <textarea {...register('legend')} className="w-full h-14 mt-2 p-6 bg-main border border-border rounded" placeholder="Description...">
                        </textarea> 
                        <p className="text-red-600 text-left">{errors.legend?.message}</p>
                </div>
                <div className='w-full gap-6'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-border font-semibold text-sm'>
                                Votre photo
                            </p>
                            <div className='w-full text-center'>
                                    <div className='w-full'>
                                        <label
                                            className="flex justify-center h-14  transition bg-main border-2 px-6 py-4  border-border border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                            <span className="flex items-center space-x-2">
                                                <BsImage className='text-subMain h-12 w-12'/>
                                                <span className="font-medium text-white text-center text-[12px]">
                                                    Ajouter un photo a votre publication
                                                </span>
                                            </span>
                                            <input {...register('files1',{
                                            onChange: handleFileChange})} accept='image/*' type="file" name="files1" className="opacity-0 w-full"/>
                                        </label>
                                    </div>
                            </div>
                            <div className='w-24 h-24 p-2 bg-main border border-border rounded'>
                                <img 
                                src={link}
                                alt=""
                                className='w-full h-full object-cover rounded'
                                />
                            </div>
                            <p className="text-red-600 text-left">{errors.files1?.message}</p>
                        </div>
                        
                        <div className='flex flex-col gap-2 w-full'>
                            <p className='text-border font-semibold text-sm'>
                                Votre video
                            </p>
                            <div className='w-full text-center'>
                                    <div className='w-full'>
                                        <label
                                            className="flex justify-center h-14  transition bg-main border-2 px-6 py-4  border-border border-dashed  rounded-md  appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                            <span className="flex items-center space-x-2">
                                                <ImPlay className='text-subMain h-12 w-12'/>
                                                <span className="font-medium text-white text-center text-[12px]">
                                                    Ajouter un video a votre publication
                                                </span>
                                            </span>
                                            <input {...register('files2',{
                                                onChange: handleFileChangeVideo})} accept='video/*' type="file" name="files2" className="opacity-0 full"/>
                                        </label>
                                    </div>
                            </div>
                            <div className='w-24 h-24 p-2 bg-main border border-border rounded'>
                                {videolink&&(
                                    <video autoPlay={play}  controls className='w-full h-full rounded'>
                                    <source src={videolink} type="video/mp4" title="titre"/>
                                    </video>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end items-center my-4'> 
                      <button type="submit" className='bg-subMain transitions hover:bg-dry border border-subMain text-white py-4  rounded w-full  flex-rows gap-6'>
                          <MdUpload/>  Publier le post
                      </button>
                    </div>
            </form>
        </div>
        </MainModal>
        {error&&(
            <div
            className="absolute -left-80 bottom-40 z-50 rounded-lg bg-subMain px-6 py-5 text-base text-danger-700 gap-4 flex justify-between items-center"
            role="alert">
            <div className="text-[12px] flex gap-8 items-center">
                <p className="text-gray-100">
                Une erreur a été survenu lors de création de votre post
                </p>
            </div>
            <div className="text-white cursor-pointer">
            <IoIosCloseCircleOutline onClick={()=>dispatch(seterrornull())}/>
            </div>
            </div>
        )}
    </>
  )
}

export default Createemission